<template>
    <div>
        <app-base-table :items="items" heading="Svz" @delete="deleteItem" className="Svz" @update="update"
                        @add="add" :headers="headers" newItemText="Neue Svz">
            <template v-slot:table-header>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="downloadSvcCSVSvcCSV"
                        >
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </template>
                    <span>Herunterladen als CSV</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="uploadCSV"
                        >
                            <v-icon>mdi-upload</v-icon>
                        </v-btn>
                    </template>
                    <span>Hochladen als CSV (alles mit ID wird überschrieben, alles ohne ID hinzugefügt, gelöscht wird nichts)</span>
                </v-tooltip>
                <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="handleFileUpload"
                />
            </template>
        </app-base-table>

    </div>
</template>
<script>
import BaseTable from "@/components/BaseTable.vue";
export default {
    props: {
        items: {
            required: true,
            type: Array
        },
        headers: {
            required: true,
            type: Array
        }
    }, methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.$store.dispatch("uploadSvcCSV",file)
        },
        uploadCSV() {
            this.$refs.fileInput.click();
        },
        deleteItem(item) {
            this.$emit("delete", item)
        },
        update(item) {
            this.$emit("update", item)
        },
        add(item) {
            this.$emit("add", item)
        },
        downloadSvcCSVSvcCSV() {
            this.$store.dispatch("downloadSvcCSV")
        }
    },
    components: {
        appBaseTable: BaseTable
    },
    filters: {
        toSvz(value) {
            if(value && value !== "") {
                const svzEntry = this.items.filter(item => item === value)[0]
                return `${svzEntry.shortTitle} (${svzEntry.id})`
            }
        },
    },
}
</script>
<style scoped>

</style>