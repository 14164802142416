var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',[_vm._v("fa-times")])],1),_c('v-toolbar-title',[_vm._v("Revisionen für "+_vm._s(_vm.revisionClassName))]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card',{staticClass:"dialog-card"},[_c('v-overlay',{attrs:{"value":_vm.revisionLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"sort":"","items":_vm.revisions,"items-per-page":15},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Show ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Feld Änderungen ")]),_c('v-card-text',[[_c('v-simple-table',{attrs:{"height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Feld ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Wert alt ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Wert neu ")])])]),_c('tbody',[_vm._l((item.changes),function(value,propertyName){return [_c('tr',{key:propertyName},[_c('td',[_vm._v(_vm._s(propertyName))]),_c('td',[_vm._v(_vm._s(value.first))]),_c('td',[_vm._v(_vm._s(value.second))])])]})],2)]},proxy:true}],null,true)})],_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Revision anzeigen ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Revision ")]),_c('v-card-text',[_vm._v(" "+_vm._s(item.data)+" ")])],1)],1)],2)],1)],1)],1)]]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }