<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-autocomplete label="Log Level" v-model="logLevel" :items="logLevelItems" item-text="text"
            item-value="index"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field v-model="scanID" label="Scan ID" single-line hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-autocomplete label="Institut ID" v-model="instituteID" :items="institutes" item-text="name"
            item-value="id"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <v-text-field v-model="userID" label="Nutzer ID" single-line hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" label="Auswahl des Auswertungszeitraums" :rules="[ dateRules ]"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="dates" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table :headers="headers" sort-by="createdAt" sort-desc :items="logs" item-key="name" class="elevation-1">

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-2" @click="requestLogs">
            Anfragen
          </v-btn>
          <v-btn color="primary" class="mr-2" @click="requestLogs" :disabled="logs.length < 1">
            <v-icon @click.stop="downloadData" style="color: white;font-size: 30px;">mdi-download
            </v-icon>
          </v-btn>
          <v-dialog v-model="infoDialog" max-width="600px">
            <v-card>
              <v-card-title class="primary dialog-title headline">Informationen</v-card-title>
              <v-card-text>
                {{info}}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="infoDialog = false">Abbrechen</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

      </template>
      <template v-slot:no-data>
        <v-btn color="primary" text @click="requestLogs">Anfragen</v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showInfo(item)">
          mdi-information-outline
        </v-icon>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{timestampToDate(item.createdAt)}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menu: false,
        dates: ["2022-03-03", "2022-03-03"],
        infoDialog: null,
        info: "",
        logLevel: 0,
        scanID: null,
        instituteID: null,
        userID: null,
        headers: [{
            text: 'Level ',
            align: 'start',
            value: 'level',
          },
          {
            text: 'Institut',
            value: 'institute'
          },
          {
            text: 'Nutzer',
            value: 'user'
          },
          {
            text: 'Nachricht',
            value: 'message'
          },
          {
            text: 'Scan',
            value: 'scan'
          },
          {
            text: 'Datum',
            value: 'createdAt'
          },
          {
            text: '',
            value: 'actions',
            sortable: false
          },
        ],
      }
    },
    mounted() {
      localStorage.timestamp = Date.now();
      this.$store.dispatch("dumpLatestLog")
      this.$store.dispatch("loadInstitutes")
      this.dates = this.range
    },
    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },
    computed: {
      range() {
        var date = new Date();
        var today = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date
          .getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        var placeholder = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
        var oneWeekAgo = placeholder.getFullYear() + '-' + ((placeholder.getMonth() + 1) < 10 ? '0' + (
          placeholder.getMonth() + 1) : (placeholder.getMonth() + 1)) + '-' + (placeholder.getDate() <
          10 ? '0' + placeholder.getDate() : placeholder.getDate());
        return [oneWeekAgo, today]
      },
      dateRangeText() {
        if (!this.dates[0])
          return ""
        var startDateArray = this.dates[0].split("-")
        if (!this.dates[1])
          return "" + startDateArray[2] + "." + startDateArray[1] + "." + startDateArray[0] + " bis "
        var endDateArray = this.dates[1].split("-")
        return "" + startDateArray[2] + "." + startDateArray[1] + "." + startDateArray[0] + " bis " + endDateArray[2] +
          "." + endDateArray[1] + "." + endDateArray[0]
      },
      institutes() {
        return this.$store.getters.getInstitutes
      },
      logs() {
        return this.$store.getters.getLogs
      },
      logLevelItems() {
        return this.$store.getters.getLogLevelItems
      },
      logLevelText() {
        return this.logs.find(x => x.level == this.logLevelItems.index).text
      }
    },
    methods: {
      dateRules() {
        if (new Date(this.dates[0]).getTime() < new Date(this.dates[1]).getTime()) {
          return true
        } else if (this.dates.length < 2) {
          return "Bitte geben Sie ein Enddatum ein."
        } else {
          return "Das Startdatum sollte vor dem Enddatum liegen."
        }
      },
      downloadData() {
        var json_pre = this.logs
        var json = JSON.stringify(json_pre);

        var csv = this.ConvertToCSV(json);
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "logs.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
      ConvertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
        var headers = '';
        for (var key in Object.keys(array[0])) {
          if (headers != '') headers += ','
          headers += Object.keys(array[0])[key];
        }

        str += headers + '\r\n';

        for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
          }

          str += line + '\r\n';
        }

        return str;
      },
      timestampToDate(timestamp) {
        return new Date(timestamp).toLocaleString().replace(",", "")
      },
      showInfo(item) {
        this.info = item.additionalInfo && item.additionalInfo != "" ? item.additionalInfo :
          "Keine zusätzlichen Informationen vorhanden"
        this.infoDialog = true
      },
      requestLogs() {
        if (this.logLevel != -1) {
          this.$store.dispatch("loadLogs", {
            level: this.logLevel,
            from: new Date(this.dates[0]).getTime() - 3600000,
            to: new Date(this.dates[1]).getTime() - 3600000 + 86399999,
            scan: this.scanID,
            institute: this.instituteID,
            user: this.userID
          })
        }
      }
    }
  }
</script>

<style scoped>
  .dialog-title {
    color: white;
  }
</style>