<template>
  <div>
    <v-card>
      <v-card-title>
        {{ heading }}
        <v-spacer></v-spacer>
        <v-btn @click="addItem()">{{ newItemText }}</v-btn>
        <template v-if="historyEnabled">
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
        </template>
        <slot name="table-header"></slot>

        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" :disableUpdate="disableUpdate"
                      single-line hide-details></v-text-field>
      </v-card-title>
      <app-base-table-raw :history-enabled="historyEnabled" @historyItem="historyItem" :headers="headers" :items="items" :search="search"
                          @deleteItem="deleteItem" @updateItem="updateItem"/>
    </v-card>
  </div>
</template>
<script>
import BaseTableRaw from "./BaseTableRaw"

export default {
  props:
      {
        items: {
          type: Array
        },
        heading: {
          type: String
        },
        headers: {
          type: Array
        },
        newItemText: {
          type: String
        },
        disableUpdate: {
          type: Boolean
        },
        historyEnabled:{
          type:Boolean,
          default:false
        }
      },

  components: {
    appBaseTableRaw: BaseTableRaw
  },
  data() {
    return {
      search: '',

    }
  },
  methods: {
    historyItem(item){
      this.$emit("historyItem",item)
    },
    history() {
      this.$emit("history")
    },
    deleteItem(item) {
      this.$emit('delete', item);
    },
    updateItem(item) {
      this.$emit('update', item);
    },
    addItem() {
      this.$emit('add');
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
}
</script>