import axios from "axios";
const state = {
}
const mutations = {

    setUser(state, events) {
        state.user = events;
    },
}
const actions = {
    async editPassword({
        dispatch
    }, user) {
        user.data.password = user.password
        await axios.put(`user/${user.data.id}`, user.data).then(function () {
            dispatch("createSuccess", {title: "Ändern erfolgreich", message:"Das Passwort wurde erfolgreich geändert."})
        }).catch(err => {
            dispatch("createError", err.response)
        });
        dispatch("loadUsers");
    },
}
const getters = {

}
export default {
    state,
    actions,
    mutations,
    getters
}