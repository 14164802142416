<template>
  <div>
    <router-view />
    <app-invoice
      @openConfig="openConfig"
      @downloadInvoicePdf="downloadInvoicePdf"
    />
  </div>
</template>
<script>
import InvoiceComponent from "../../components/Invoice/Invoice.vue";

export default {
  data() {
    return {};
  },
  components: {
    appInvoice: InvoiceComponent,
  },
  computed: {},
  methods: {
    openConfig() {
      this.$router.push({
        name: "InvoiceConfig",
      });
    },
    downloadInvoicePdf(from, to) {
      if (!from) return;
      from = new Date(from).getTime();
      to = to ? new Date(to).getTime() : new Date().getTime();
      this.$store.dispatch("downloadInvoicePdf", { from, to });
    },
  },
  created() {},
};
</script>