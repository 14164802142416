<template>  
    <div>
        <app-global-notification-update v-if="SelectedGlobalNotification" :item="SelectedGlobalNotification" @cancel="cancel" @update="updateGlobalNotification"/>
    </div>
</template>
<script>
import GlobalNotificationUpdateComponent from "../../components/GlobalNotification/GlobalNotificationUpdate"
export default {
    components:{
        appGlobalNotificationUpdate:GlobalNotificationUpdateComponent
    },
    computed:{
        GlobalNotificationID(){
            return this.$route.params.globalNotificationID
        },
        SelectedGlobalNotification(){
            return this.$store.getters.getSelectedGlobalNotification;
        }
    },
    methods:{
        updateGlobalNotification(globalNotificationID){
            this.$store.dispatch("editGlobalNotification",globalNotificationID)
            this.$router.push({
                    name: "GlobalNotification"
            });
        },
        cancel(){
            this.$router.push({
                    name: "GlobalNotification"
            });
        },
        loadGlobalNotification(newID){
            if(newID){
                this.$store.dispatch("loadGlobalNotification",newID);
            }
        }
    },
    watch:{
        GlobalNotificationID(newID){
            this.loadGlobalNotification(newID);
        }
    },
    created(){
        this.loadGlobalNotification(this.GlobalNotificationID);
    }
}
</script>