
<template>
  <app-revision-by-id></app-revision-by-id>
</template>
<script>
import appRevisionById from "../../components/Revision/RevisionById.vue"
export default {
  components:{
    appRevisionById
  }
}
</script>