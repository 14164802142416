<template>
  <app-dialog dialog title="Rechnungserstellung konfigurieren" @close="close">
    <router-view />
    <div>
      <app-invoice
        history-enabled
        v-if="features"
        heading="Features"
        :headers="headers"
        :items="features"
        @add="add"
        @update="update"
        @delete="deleteFeature"
        @historyItem="historyItem"
        @history="history"
        newItemText="Neues Feature"
      />
      <app-feature-edit
        v-model="dialog"
        :selectedFeature="selectedFeature"
        @close="closeDialog"
        @save="createFeature"
        @update="updateFeature"
      />
    </div>
    <app-revision-dialog :dialog="revisionDialog" @save="deleteWithRevision"></app-revision-dialog>
  </app-dialog>
</template>
<script>
import InvoiceComponent from "../../components/BaseTable.vue";
import FullscreenDialogComponent from "../../components/FullScreenDialog.vue";
import FeatureEditComponent from "../../components/Invoice/FeatureCreate.vue";
import AppRevisionDialog from "../../components/Revision/RevisionDialog.vue";

export default {
  data() {
    return {
      revisionDialog:false,
      revisionItem:null,
      headers: [
        {
          text: "Institut",
          align: "start",
          value: "institute",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Preis",
          value: "price",
        },
        {
          text: "Aktionen",
          value: "actions",
        },
      ],
      dialog: false,
      selectedFeature: undefined,
    };
  },
  components: {
    AppRevisionDialog,
    appInvoice: InvoiceComponent,
    appDialog: FullscreenDialogComponent,
    appFeatureEdit: FeatureEditComponent,
  },
  computed: {
    features() {
      const features = this.$store.getters.getFeatures;
      const institutes = this.$store.getters.getInstitutes;
      if (!features || !institutes) return [];
      features.forEach(async (feature) => {
        const institute = institutes.find(
          (institut) => institut.id === feature.instituteId
        );
        feature.institute = institute ? institute.name : feature.instituteId;
      });
      return features;
    },
  },
  methods: {
    historyItem(item) {
      this.$router.push({
        name: 'RevisionForId',
        params: {
          className: "Feature",
          id: item.id
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "Feature"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    add() {
      this.selectedFeature = undefined;
      this.dialog = true;
    },
    createFeature(feature) {
      this.$store.dispatch("addFeature", feature);
      this.dialog = false;
    },
    close() {
      this.$router.push({
        name: "Invoice",
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    update(feature) {
      this.selectedFeature = feature;
      this.dialog = true;
    },
    updateFeature(feature) {
      this.$store.dispatch("updateFeature", feature);
      this.dialog = false;
    },
    deleteFeature(feature) {
      this.revisionDialog =true
      this.revisionItem = feature
    },
    deleteWithRevision(revision){
      this.$store.dispatch("deleteFeature", {feature:this.revisionItem,revision});
      this.revisionDialog =false
    }
  },
  created() {
    this.$store.dispatch("loadInstitutes");
    this.$store.dispatch("loadFeatures");
  },
};
</script>