<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="500"
    >
      <v-card>
        <v-card-title class="primary error-dialog-title">
          Errorcode: {{ error.status }}
        </v-card-title>

        <v-card-text v-if="error.data && error.data.message">
          {{ error.data.message }}
        </v-card-text>
        <v-card-text v-else>
          {{ error.data }}
        </v-card-text>

        <div v-if='"EXPIRED" == error.status' style="width: 100%; display: flex; justify-content: end"><v-btn style="margin: 10px" color="primary" text @click="setFieldsAsOptional()">
                          Freigeben
                        </v-btn></div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click.stop="ok"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        
      }
    },
    props: {
      value: Boolean,
      error: null
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      ok() {
        this.show = false
      },
      setFieldsAsOptional(){
        console.log(this.error)
        this.$store.dispatch("setFieldsAsOptional", this.error.scanID).then( () => {
          })
          this.show = false
      },
    }
  }
</script>

<style scoped>
.error-dialog-title{
  color: white;
}
</style>