import axios from "axios";

const state = {
    reports: [],
    summary: null,
    globalReportingConfig: null,
    globalReportingSummary:null
}
const mutations = {
    setReports(state, reports) {
        state.reports = reports;
    },
    setSummary(state, summary) {
        state.summary = summary;
    },
    setGlobalReportingConfig(state, config) {
        state.globalReportingConfig = config
    },
    setGlobalReportingSummary(state, summary) {
        state.globalReportingSummary = summary
    }
}
const actions = {
    async loadPdfReport({
                            dispatch
                        }, params) {
        axios.get(`institute/${params.id}/reporting/pdf`, {responseType: 'blob', params: params}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Bericht.pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async loadReports({
                          commit
                      }, params) {
        try {
            var userResponse = (await axios.get(`institute/${params.id}/reporting`, {
                params: params
            })).data;


            var summary = userResponse.summary

            var reports = []
            userResponse.rawData.forEach(async (report) => {


                reports.push({
                    institute: report.institute,
                    createdAt: report.createdAt,
                    action: report.action,
                    user: report.user,
                    scanID: report.scanID,
                })
            })
            commit("setReports", reports);
            commit("setSummary", summary);
        } catch (error) {
            console.log(error)
        }
    },
    async loadAllInstituteReports({
                                      dispatch
                                  }, params) {
        axios.get(`reporting/global/pdf`, {
            responseType: 'blob',
            params: {
                from: params.from,
                to: params.to
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Bericht.pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async loadGlobalReportingConfig({
                                        commit, dispatch
                                    }) {
        axios.get(`reporting/global/config`).then(response => {
            commit("setGlobalReportingConfig", response.data)
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async loadGlobalReportingSummary({commit, dispatch},params) {
        axios.get(`reporting/global`,{params:params}).then(response => {
            commit("setGlobalReportingSummary", response.data)
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async setGlobalReportingConfig({commit, dispatch}, editItem) {
        axios.put(`reporting/global/config`,editItem.globalReportingConfig,{
            headers: {
                "Revision-Change-Reason-Type": editItem.revision.changeReasonType,
                    "Revision-Description": editItem.revision.description,
            }
        }).then(response => {
            commit("setGlobalReportingConfig", response.data)
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async clearReports({
                           commit
                       }) {
        try {

            var reports = []

            commit("setReports", reports);
            commit("setSummary", null);
        } catch (error) {
            console.log(error)
        }
    },
    epochToDate(epoch) {
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        return new Date(epoch).toLocaleString("de-DE", options).replace(",", "");
    },
}
const getters = {
    getReports(state) {
        return state.reports;
    },
    getSummary(state) {
        return state.summary;
    },
    getGlobalReportingConfig(state){
        return state.globalReportingConfig;
    },
    getGlobalReportingSummary(state){
        return state.globalReportingSummary;
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}