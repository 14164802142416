<template>
  <div class="text-center">
    <v-dialog
        :value="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Revisionsmeldung
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-card-text>
            <v-row>
              <v-text-field
                  label="Meldung"
                  :rules="descriptionRules"
                  v-model="revision.description"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-select
                  :items="items"
                  :rules="changeReasonTypeRules"
                  label="Einganskanal"
                  v-model="revision.changeReasonType"
              ></v-select>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="save"
          >
            Abschicken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      valid: true,
      descriptionRules: [
        v => !!v || 'Description is required',
      ],
      changeReasonTypeRules: [
        v => !!v || 'Die Reason is required',
      ],
      revision: {
        description: null,
        changeReasonType: null
      },
      items: ["PHONE", "MAIL", "OTHERS"]
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.revision = {
          description: null,
          changeReasonType: null
        }
        if(this.$refs.form){
          this.$refs.form.resetValidation()
        }
      }
    }
  },
  methods: {
    async save() {
      await this.$refs.form.validate()
      if (this.valid) {
        let revision = JSON.parse(JSON.stringify(this.revision))
        revision.description = revision.description.replace(/(\r\n|\n|\r)/gm, ' ');
        revision.description = revision.description.replace(/[^a-zA-Z0-9äöüÄÖÜ\-. ]/g, '')
        this.$emit("save", revision)
      }

    }
  }
}
</script>