<template>
    <div>
        <app-global-mail v-if="globalMail" :item="globalMail" @update="update"/>
    </div>
</template>
<script>
    import GlobalMailComponent from "../../components/GlobalMail/GlobalMail.vue"
    export default {
        data() {
            return {}
        },
        components: {
            appGlobalMail: GlobalMailComponent
        },
        computed: {
            globalMail() {
                return this.$store.getters.getGlobalMail;
            },
        },
        methods: {
            update(item) {
                this.$store.dispatch("editGlobalMail",item);
            },
        },
        created() {
            this.$store.dispatch("loadGlobalMail");
        }
    }
</script>