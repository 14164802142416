import axios from "axios";

const state = {
    features: null
}

const mutations = {
    setFeatures(state, value) {
        state.features = value;
    },
}
const actions = {
    async loadFeatures({
        commit
    }) {
        try {
            var featureResponse = (await axios.get(`invoice/feature`)).data;
            commit("setFeatures", featureResponse);
        } catch (error) {
            console.error(error)
        }
    },
    async addFeature({
        dispatch
    }, addItem) {
        await axios.post(`invoice/feature`, addItem.feature,{
            headers:{
                "Revision-Change-Reason-Type":addItem.revision.changeReasonType,
                "Revision-Description":addItem.revision.description,
            }
        });
        dispatch("loadFeatures");
    },
    async updateFeature({
        dispatch
    }, editItem) {
        await axios.put(`invoice/feature/${editItem.feature.id}`, editItem.feature,{
            headers:{
                "Revision-Change-Reason-Type":editItem.revision.changeReasonType,
                "Revision-Description":editItem.revision.description,
            }
        });
        dispatch("loadFeatures");
    },
    async deleteFeature({
        dispatch
    }, deleteItem) {
        await axios.delete(`invoice/feature/${deleteItem.feature.id}`,{
            headers:{
                "Revision-Change-Reason-Type":deleteItem.revision.changeReasonType,
                "Revision-Description":deleteItem.revision.description,
            }
        });
        dispatch("loadFeatures");
    },
    async downloadInvoicePdf({
        dispatch
    }, params) {
        axios.get(`invoice`, { responseType: 'blob', params: params }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const currentDateISO = new Date().toISOString()
            link.href = url;
            link.setAttribute('download', `Rechnung ${currentDateISO}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            dispatch("createError", err.response)
        })
    }
}
const getters = {
    getFeatures(state) {
        return state.features;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}