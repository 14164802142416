<template>
  <div>
    <v-dialog v-model="dialog" persistent width="660">
      <template v-slot:activator="{ on }">
        <v-text-field :disabled="disabled" label="Von Bis" v-on="on" v-model="dateRangeText"
                      prepend-inner-icon="fa-clock" :rules="[v => !!v || 'Zeit ist erforderlich']">
        </v-text-field>
      </template>
      <v-card>
        <v-col cols="12">
          <v-date-picker v-model="valueInt" range :min="min" :max="max" full-width>
          </v-date-picker>
        </v-col>
        <v-col>
          <v-btn @click="ok">OK</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>
export default {
  props: ["max", "min", "value", "disabled"],
  data() {
    return {
      dialog: false,
      valueInt: ""
    }
  },
  watch: {
    value(newValue) {
      this.valueInt = newValue
    },
  },
  computed: {
    dateRangeText() {
      return this.valueInt.slice(0).sort().join(' ~ ')
    },
    data() {
      return {
        validFrom: this.valueInt.slice(0).sort()[0],
        validTill: this.valueInt.slice(0).sort()[1]
      }
    }
  },
  methods: {
    ok() {
      this.dialog = false
      this.$emit("change", this.data)
    }
  },
  created() {
    this.valueInt = this.value
  }
}
</script>