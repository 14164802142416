<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancel()">
            <v-icon>fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Revisionen für {{ revisionClassName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-card class="dialog-card">
          <v-overlay :value="revisionLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-data-table :headers="headers" sort :items="revisions" :items-per-page="15" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <template>
                <div class="text-center">
                  <v-dialog width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark v-bind="attrs" v-on="on">
                        Show
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Feld Änderungen
                      </v-card-title>
                      <v-card-text>
                        <template>
                          <v-simple-table height="300px">
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left">
                                  Feld
                                </th>
                                <th class="text-left">
                                  Wert alt
                                </th>
                                <th class="text-left">
                                  Wert neu
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <template v-for="(value, propertyName) in item.changes">
                                  <tr :key="propertyName">
                                    <td>{{ propertyName }}</td>
                                    <td>{{ value.first }}</td>
                                    <td>{{ value.second }}</td>
                                  </tr>
                              </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </template>
                        <v-dialog width="500">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn dark v-bind="attrs" v-on="on">
                              Revision anzeigen
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                              Revision
                            </v-card-title>
                            <v-card-text>
                              {{item.data}}
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  methods: {
    cancel() {
      this.$router.push({
        name: this.$route.query.caller
      })
    },
    isFieldChanged(propertyName, item) {
      return item.changedFields.includes(propertyName)
    }
  },
  data() {
    return {
      dialog: true,
      revisionDialog:false,
      headers: [{
        text: 'ID',
        align: 'start',
        value: 'id',
      },
        {
          text: 'Timestamp',
          value: 'timestamp'
        },
        {
          text: 'Revisionstyp',
          value: 'revisionType'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: 'Beschreibung',
          value: 'description'
        },
        {
          text: 'Grund',
          value: 'changeReasonType'
        },
        {
          text: 'Aktionen',
          value: 'actions'
        },
      ],
    }
  },
  computed: {
    revisionLoading() {
      return this.$store.getters.getRevisionLoading;
    },
    revisionId() {
      return this.$route.params.id;
    },
    revisionClassName() {
      return this.$route.params.className;
    },
    caller() {
      return this.$route.query.caller
    },
    revisions() {
      return this.$store.getters.getSelectedRevision;
    },
  },
  created() {
    this.$store.dispatch("loadRevision", {
      className: this.revisionClassName,
      id: this.revisionId
    })
  },
}
</script>