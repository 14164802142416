<template>
  <v-dialog
    v-model="show"
    max-width="75%"
    class="institute-basic-wrapper dialog"
  >
    <v-card class="institute-basic-wrapper">
      <v-toolbar max-height="64px" dark color="primary">
        <v-toolbar-title v-if="selectedFeature">
          Feature {{ item.id }} bearbeiten
        </v-toolbar-title>
        <v-toolbar-title v-else> Feature hinzufügen </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Bezeichnung</th>
                  <th class="text-left">Wert</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name des Features</td>
                  <td>
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td>Preis</td>
                  <td>
                    <v-text-field
                      v-model.number="item.price"
                      label="Preis in Euro"
                      type="number"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td>Institute, für welche dieses Feature gelten soll</td>
                  <td>
                    <v-select
                      v-model="item.instituteIds"
                      :items="institutes"
                      item-text="name"
                      item-value="id"
                      label="Institute"
                      :rules="[rules.arrayNotEmpty]"
                      :disabled="instituteDisabled"
                      multiple
                    ></v-select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Reporting Aktionen, welche in diesem Feature beinhaltet
                    sind.
                  </td>
                  <td>
                    <v-select
                      v-model="item.reportingActions"
                      :items="selectableReportingActions"
                      label="Reporting Aktion"
                      :rules="[rules.arrayNotEmpty]"
                      multiple
                    ></v-select>
                  </td>
                </tr>
              </tbody>
              <v-card-actions>
                <v-btn @click="save()" color="primary">save</v-btn>
              </v-card-actions>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
    </v-card>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </v-dialog>
</template>

<script>
import AppRevisionDialog from "../Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedFeature: Object,
  },
  data() {
    return {
      revisionDialog:false,
      item: {
        id: "",
        name: "",
        price: 0.0,
        instituteIds: [],
        reportingActions: [],
      },
      selectableReportingActions: [
        "LEG_CREATED",
        "LEG_COMPLETED",
        "LEG_DELETED_BY_USER",
        "LEG_DELETED_AFTER_INACTIVITY",
        "LEG_EDITED",
        "LEG_DELETED_AFTER_COMPLETION",
        "LEG_AUTO_COMPLETED",
        "ADDRESS_UPDATED",
        "POTENTIAL_CUSTOMERS_COMPLETED",
        "ADDRESS_COMPARED",
        "ADDRESS_CREATED",
      ],
      rules: {
        required: (v) => !!v || v >= 0 || "Dieses Feld ist verpflichtend!",
        arrayNotEmpty: (v) => v.length > 0 || "Dieses Feld ist verpflichtend!",
      },
      valid: true,
    };
  },
  created() {
    this.$store.dispatch("loadInstitutes");
  },

  computed: {
    institutes() {
      return this.$store.getters.getInstitutes;
    },
    selected() {
      return [];
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    instituteDisabled() {
      return this.selectedFeature !== undefined;
    },
  },
  watch: {
    //either load updatable item or initialize with empty values
    show: function (value) {
      if (value === false) return;
      if (this.selectedFeature) {
        this.item = {
          id: this.selectedFeature.id,
          name: this.selectedFeature.name,
          price: this.selectedFeature.price,
          instituteIds: [this.selectedFeature.instituteId],
          reportingActions: this.selectedFeature.reportingActions,
        };
      } else {
        //$refs.form possible undefined
        if (this.$refs.form) this.$refs.form.reset();
      }
    },
  },
  methods: {
    save() {
      this.revisionDialog = true;
    },
    saveWithRevision(revision){
      this.$refs.form.validate();
      if (!this.valid) return;
      const jsonFeature = JSON.parse(JSON.stringify(this.item));
      this.$refs.form.reset();
      if (this.selectedFeature) this.$emit("update", {feature:jsonFeature,revision});
      else this.$emit("save", {feature:jsonFeature,revision});
      this.revisionDialog = false;
    },
    close() {
      this.$refs.form.reset();
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.institute-basic-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.institute-basic-wrapper:hover {
  box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}

.dialog {
  width: 50%;
}
</style>