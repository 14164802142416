import axios from "axios";
const state = {
    issuer: [],
}
const mutations = {

    setIssuer(state, events) {
        state.issuer = events;
    },
}
const actions = {
    async loadIssueres({
        commit
    }, params) {
        try {
            var issuerResponse = (await axios.get(`issuerMappings`, {
                params: params
            })).data;


            var issuers = []
            issuerResponse.forEach(async (mandant) => {
                var images = []
                mandant.images.forEach(async (image) => {
                try {
                    image = (await axios.get(image)).data
                } catch (x) {
                    console.log(x)
                }
                images.push(image)
                })

                issuers.push({
                    id: mandant.id,
                    issuerCode: mandant.issuerCode,
                    issuerName: mandant.issuerName,
                    images: images,
                    approved: mandant.approved,
                })
            })
            commit("setIssuer", issuers);
        } catch (error) {
            console.log(error)
        }
    },
    async editIssuer({
        dispatch
    }, editItem) {
        await axios.put(`issuerMappings/${editItem.issuerMapping.id}`,{
            headers:{
                "Revision-Change-Reason-Type":editItem.revision.changeReasonType,
                "Revision-Description":editItem.revision.description,
            }
        });
        dispatch("loadIssueres");
    },
    async deleteIssuer({
        dispatch
    }, deleteItem) {
        await axios.delete(`/issuerMappings/${deleteItem.issuerMapping.id}`,{
            headers:{
                "Revision-Change-Reason-Type":deleteItem.revision.changeReasonType,
                "Revision-Description":deleteItem.revision.description,
            }
        });
        dispatch("loadIssueres");

    }
}
const getters = {
    getIssueres(state) {
        return state.issuer;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}