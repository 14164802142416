<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-2" @click="toConfig">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <app-date-time-picker
            style="width: 100%"
            :value="dateTime"
            @change="changeDate"
          >
          </app-date-time-picker>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="downloadInvoicePdf">
            PDF-Report
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import appDateTimePicker from "../DateRangePicker.vue";

export default {
  components: {
    appDateTimePicker,
  },
  props: {
    summary: {
      type: Array,
    },
  },
  computed: {
    dateTime() {
      return [this.requestParams.from, this.requestParams.to];
    },
  },
  data() {
    return {
      requestParams: {
        from: "",
        to: "",
      },
    };
  },
  methods: {
    toConfig() {
      this.$emit("openConfig");
    },
    changeDate(date) {
      this.requestParams.from = date.validFrom;
      this.requestParams.to = date.validTill;
    },
    downloadInvoicePdf() {
      this.$emit(
        "downloadInvoicePdf",
        this.requestParams.from,
        this.requestParams.to
      );
    },
  },
};
</script>
<style scoped>
.resultCard {
  text-align: center;
}
</style>