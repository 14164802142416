<template>
    <div>
        <app-svz-update v-if="SelectedSvz" :svzs="svzs" :item="SelectedSvz" @cancel="cancel" @update="updateSvz"/>
    </div>
</template>
<script>
import SvzUpdateComponent from "../../components/Svz/SvzUpdate"
export default {
    components:{
        appSvzUpdate:SvzUpdateComponent
    },
    computed: {
        svzs() {
            return this.$store.getters.getSvzs;
        },
        SvzID(){
            return this.$route.params.svzID
        },
        SelectedSvz(){
            return this.$store.getters.getSelectedSvz;
        }
    },
    methods:{
        updateSvz(svzID){
            this.$store.dispatch("editSvz",svzID)
            this.$router.push({
                name: "Svz"
            });
        },
        cancel(){
            this.$router.push({
                name: "Svz"
            });
        },
        loadSvz(newID){
            if(newID){
                this.$store.dispatch("loadSvz",newID);
            }
        }
    },
    watch:{
        SvzID(newID){
            this.loadSvz(newID);
        }
    },
    created(){
        this.loadSvz(this.SvzID);
    }
}
</script>