<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Admin Anmeldung</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field id="username" v-model="username" prepend-icon="mdi-account" name="login"
                              label="Benutzername" type="text" v-on:keyup.enter="login"></v-text-field>
                           <v-text-field id="password" v-model="password" prepend-icon="mdi-lock" name="password"
                              label="Passwort" type="password" v-on:keyup.enter="login"></v-text-field>
                        </v-form>
                        <p v-if="errorCode" class="text-center error-message">
                           {{ errorTitle }}
                        </p>
                     </v-card-text>
                     <v-card-actions>
                     <p style="margin-bottom: 0px;
    font-size: 12px; color:grey">Version: {{ versionNumber }}</p>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click.stop="login">Anmelden</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
   export default {
      name: 'Login',
      data() {
         return {
            username: "",
            password: "",
         }
      },
      computed: {
         errorTitle() {
            switch (this.errorCode) {
               case 991:
                  return "Benutzername oder Passwort falsch eingegeben.";
               case 981:
                  return "Ihr OSPlus-Passwort ist abgelaufen. Bitte nehmen Sie in OSPlus eine Passwortänderung vor.";
               case 992:
                  return "Sie haben in OSPlus leider keine Berechtigung, Legitimationen zu bearbeiten.";
               case 100001:
                     return "Sie wurden automatisch vom System abgemeldet."
              case 400:
                return this.errorData;
               default:
                  return "Ein unerwarteter Serverfehler ist aufgetreten.";
            }
         },
         errorCode() {
            return this.$store.getters.getErrorCode
         },
        errorData(){
          return this.$store.getters.getErrorData
        },
         versionNumber() {
            return this.$store.getters.getVersionNumber
         }
      },
      props: {
         source: String,
      },
      mounted() {
         this.$store.dispatch("fetchVersionNumber")
      },
      methods: {
         login() {
            this.$store.dispatch("loginUser", {
               username: this.username,
               password: this.password
            }).then(() => {
               this.$store.dispatch("relogin");
            })
            this.errorOccured = false
         }
      }
   };
</script>

<style scoped>
   .error-message {
      color: #f00
   }
</style>