<template>
  <div>
    <app-global-reporting @loadPdfReport="loadPdfReport" @navigateToConfig="navigateToConfig" @loadSummary="loadSummary" :summary="ReportingSummary"></app-global-reporting>
    <router-view></router-view>
  </div>

</template>
<script >
import appGlobalReporting from "../../components/GlobalReporting/GlobalReporting.vue"
export default {
  components:{
    appGlobalReporting
  },
  computed:{
    ReportingSummary(){
      return this.$store.getters.getGlobalReportingSummary
    }
  },
  methods:{
    loadPdfReport(data){
      this.$store.dispatch("loadAllInstituteReports", data)
      this.$router.push({name:"GlobalReporting"})
    },
    navigateToConfig(){
      this.$router.push({name:"GlobalReportingConfigUpdate"})
    },
    loadSummary(data){
      this.$store.dispatch("loadGlobalReportingSummary", data)
    }
  }
}
</script>

<style scoped>

</style>