<template>
  <app-fullscreen-dialog dialog title="Reporting Konfiguration" @close="close">
  <v-container>
    <v-card class="institute-basic-wrapper" >
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Bezeichnung
            </th>
            <th class="text-left">
              Wert
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Enabled Auto Email</td>
            <td>
              <v-checkbox v-model="item.mailEnabled"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Mail Addressen</td>
            <td>
              <v-combobox
                  :disabled="!item.mailEnabled"
                  v-model="item.reportingEmails"
                  :items="emails"
                  :search-input.sync="search"
                  hide-selected
                  hint="Durch enter hinzufügen"
                  label="E-Mail Empfänger hinzufügen."
                  multiple
                  persistent-hint
                  small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Email Addresse "<strong>{{ search }}</strong>" hinzufügen.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </td>
          </tr>
          <tr>
            <td>Mail Subject</td>
            <td>
              <v-text-field :disabled="!item.mailEnabled" v-model="item.mailSubject"  label="Subject">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Email Body Template</td>
            <v-container fluid>
              <v-textarea
                  label="Email Template"
                  :disabled="!item.mailEnabled"
                  v-model="item.mailTemplateHtml"
              ></v-textarea>
            </v-container>
          </tr>
          <tr>
            <td>PDF Template</td>
            <v-container fluid>
              <v-textarea
                  label="PDF Template"
                  v-model="item.pdfTemplateHtml"
              ></v-textarea>
            </v-container>
          </tr>
          </tbody>
          <v-btn @click="save()" color="primary">save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </app-fullscreen-dialog>
</template>

<script>
import appFullscreenDialog from "../FullScreenDialog.vue";
import AppRevisionDialog from "../Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog, appFullscreenDialog},
  props: {
    item:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      emails:[],
      search:"",
      dialog:true,
      revisionDialog: false,
    }
  },
  created() {},


  computed: {},

  methods: {
    save(){
      this.revisionDialog = true
    },
    saveWithRevision(revision) {
      this.$emit("update", {globalReportingConfig:JSON.parse(JSON.stringify(this.item)),revision:revision})
      this.revisionDialog = false
    },
    close(){
      this.$emit("close")
    },
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "GlobalReportingConfig"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
  },
}
</script>

<style scoped>
.institute-basic-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  margin-bottom: 40px;

}

.institute-basic-wrapper:hover {
  box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>