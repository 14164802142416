<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="cancel()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Event aktualisieren</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="save()">
                                Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card class="dialog-card">
                        <v-list three-line subheader>
                            <v-subheader>Allgemein</v-subheader>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="svzValue" v-model="item.svzValue"
                                                  :rules="[v => !!v || 'svzValue ist erforderlich']" required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field label="shortTitle" v-model="item.shortTitle"
                                                  :rules="[v => !!v || 'shortTitle ist erforderlich']" required></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-combobox v-model="item.svzType" :items="svzTypes" label="svzType"
                                                placeholder="Feld auswählen..."></v-combobox>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete label="refSvz" v-model="item.refSvz" :items="svzs"
                                                    item-text="shortTitle" item-value="id"></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list three-line subheader>
                            <v-subheader>Sonstiges</v-subheader>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea label="shortDescription" v-model="item.shortDescription"
                                                hint="shortDescription für das SVZ"></v-textarea>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox v-model="item.enabled" label="aktivert"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-list>
                    </v-card>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        item: {
            required: true,
            type: Object
        },
        svzs:{
            required:true,
            type:Array
        }
    },
    data() {
        return {
            dialog: true,
            valid: true,
            svzTypes: ["SALUTATION", "TITLE", "PROFESSIONAL_POSITION", "INDUSTRY", "MARITAL_STATUS", "PROPERTY_STATUS", "COUNTRY_CODE", "GENDER","POTENTIAL_CUSTOMER_TYPE"],
        }
    },
    methods: {
        save() {
            if (this.validate()) {
                this.$emit('update', this.item);
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        validate() {
            return this.$refs.form.validate()
        },
    },
}
</script>