<template>
    <div>
        <app-svz-create @save="createNewSvz" :svzs="svzs" :instituteId="selectedInstituteID" @cancel="cancel" />
    </div>
</template>
<script>
import SvzCreateComponent from "../../components/Svz/SvzCreate"
export default {
    components: {
        appSvzCreate: SvzCreateComponent
    },
    methods: {
        createNewSvz(svz) {
            this.$store.dispatch("addSvz", svz);
            this.$router.push({
                name: "Svz"
            });
        },
        cancel() {
            this.$router.push({
                name: "Svz"
            });
        },
    },
    computed: {
        svzs() {
            return this.$store.getters.getSvzs;
        },
        selectedInstituteID(){
            return this.$route.params.instituteID
        }
    },
}
</script>