   const state = {
       error: "",
       activeError: true,
   }
   const mutations = {
       setError(state, error) {
           state.error = error;
       },
       setActiveError(state, activeError) {
        state.activeError = activeError;
    },
   }
   const actions = {
       createError({
           commit
       }, error) {
        console.log(error)
            commit("setActiveError", true)
           commit("setError", error)
       },
       changeActiveError({
        commit
    }, activeError) {
        commit("setActiveError", activeError)
    },
   }
   const getters = {
       getError(state) {
           return state.error
       },
       getActiveError(state) {
        return state.activeError
    }
   }
   export default {
       state,
       actions,
       mutations,
       getters
   }