import Vue from 'vue'
import VueRouter from 'vue-router'

import GlobalNotificationComponent from '@/views/GlobalNotification/Index.vue'
import GlobalNotificationAddComponent from '@/views/GlobalNotification/Create.vue'
import GlobalNotificationEditComponent from '@/views/GlobalNotification/Update.vue'
import GlobalNotificationRemoveComponent from '@/views/GlobalNotification/Delete.vue'
import GlobalMail from "@/views/GlobalMail/Index.vue"
import SvzComponent from "@/views/Svz/Index.vue";
import SvzEditComponent from "@/views/Svz/Update.vue";
import SvzAddComponent from "@/views/Svz/Create.vue";
import SvzRemoveComponent from "@/views/Svz/Delete.vue";
import LogIndex from "@/views/Log/Index.vue"
import LogDetails from "@/views/Log/LogDetails.vue";
import ReportingComponent from "@/views/Reporting/Index.vue"
import GlobalConfigUpdates from "@/views/Reporting/Config.vue"
import InvoiceComponent from "@/views/Invoice/Index.vue"
import InvoiceConfigComponent from "@/views/Invoice/Config.vue"

import RevisionForID from '@/views/Revision/RevisionById'
import Revisions from '@/views/Revision/RevisionAll'

Vue.use(VueRouter)

const routes = [
  {
    path: "/home/Revision/:className/:id",
    name: "RevisionForId",
    component: RevisionForID
  },
  {
    path: "/home/Revision/:className",
    name: "Revisions",
    component: Revisions
  },
  {
    path: '/home/behoerdenmapping',
    name: 'Behördenmapping',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/IssuerMapping.vue')
  },
  {
    path: '/home/einstellungen',
    name: 'Einstellungen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Settings.vue')
  },
  {
    path: '/home/nutzerverwaltung',
    name: 'Nutzerverwaltung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/UserTable.vue')
  },
  {
    path: '/home/laendertabelle',
    name: 'Ländertabelle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/CountryTable.vue')
  },
  {
    path: '/home/mandanten',
    name: 'Mandanten',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/TableInstitut.vue')
  },
  {
    path: '/home/log/:type',
    name: 'Log',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LogIndex,
    children: [{
      path: ":logId",
      name: "LogInfo",
      component: LogDetails
    }]
  },
  {
    path: '/home/scan',
    name: 'Scan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/CreateScan.vue')
  },
  {
    path: "/home/svz/:instituteID",
    name: "Svz",
    component: SvzComponent,
    children: [{
      path: "Create",
      name: "SvzCreate",
      component: SvzAddComponent
    },
    {
      path: "Update/:svzID",
      name: "SvzUpdate",
      component: SvzEditComponent
    },
    {
      path: "Delete/:svzID",
      name: "SvzDelete",
      component: SvzRemoveComponent
    },
    ]
  },
  {
    path: "/home/notification",
    name: "GlobalNotification",
    component: GlobalNotificationComponent,
    children: [{
      path: "Create",
      name: "GlobalNotificationCreate",
      component: GlobalNotificationAddComponent
    },
    {
      path: "Update/:globalNotificationID",
      name: "GlobalNotificationUpdate",
      component: GlobalNotificationEditComponent
    },
    {
      path: "Delete/:globalNotificationID",
      name: "GlobalNotificationDelete",
      component: GlobalNotificationRemoveComponent
    },
    ]
  },
  {
    path: "/home/globalMail",
    name: "GlobalMail",
    component: GlobalMail,
  },
  {
    path: "/home/reporting",
    name: "GlobalReporting",
    component: ReportingComponent,
    children: [{
      path: "config",
      name: "GlobalReportingConfigUpdate",
      component: GlobalConfigUpdates
    }]
  },
  {
    path: "/home/invoice",
    name: "Invoice",
    component: InvoiceComponent,
    children: [
      {
        path: "config",
        name: "InvoiceConfig",
        component: InvoiceConfigComponent
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'Mandanten',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/TableInstitut.vue'),

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
