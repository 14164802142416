

<template>
  <div>
      <app-log-details v-if="selectedLog" :item="selectedLog" @close="close"></app-log-details>
  </div>
</template>

<script>
import LogDetails from "@/components/Log/LogDetails.vue";
export default {

    computed:{
        selectedLog(){
          return this.$store.getters.getSelectedRequestLog
        },
        LogId(){
            return this.$route.params.logId
        },
    },
    components:{
        appLogDetails:LogDetails
    },
    created(){
        this.loadRequestLog()
    },
    methods:{
        loadRequestLog(){
            this.$store.dispatch("loadRequestLog",this.LogId)
        },
        close(){
            this.$router.push({
                name: "Log",
                params: {
                    type: "RequestLog"
                }
            });
        }
    },
    watch:{
        LogId(newID){
            this.loadGlobalNotification(newID);
        }
    },
}
</script>
<style scoped>

</style>