import axios from "axios";
const state = {
    svzs: [],
    svzLoading:false,
    selectedSvz:null
}
const mutations = {
    setSvz(state, value) {
        state.svzs = value;
    },
    setSvzLoading(state,value){
        state.svzLoading = value
    },
    setSelectedSvz(state,value){
        state.selectedSvz = value
    }

}
const actions = {
    async downloadSvcCSV({getters}) {
        try {
            const response = await axios.get(`/svz/institute/${getters.getSelectedInstitute.id}/csv`, {
                responseType: 'blob' // Der Antworttyp wird auf 'blob' gesetzt, um binäre Daten zu erhalten
            });

            // Erzeuge einen Download-Link für die CSV-Datei
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Erzeuge ein Link-Element und klicke es an, um den Download zu starten
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data.csv');
            document.body.appendChild(link);
            link.click();

            // Entferne den Link aus dem DOM
            document.body.removeChild(link);
        } catch (error) {
            console.error('Fehler beim Herunterladen der CSV-Datei:', error);
        }
    },
    async uploadSvcCSV({ dispatch,getters }, file) {
        // eslint-disable-next-line no-useless-catch
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(`/svz/institute/${getters.getSelectedInstitute.id}/csv`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            dispatch("loadSvzs")
        } catch (error) {
            throw error;
        }
    },
    async addSvz({commit,dispatch,getters},svzData) {
        try {
            commit("setSvzLoading",true)
            console.log("svzData",svzData)
            const svzResponse = (await axios.post(`svz/institute/${svzData.instituteId}`,svzData)).data;
            commit("setSelectedSvz", svzResponse);
            commit("setSvzLoading",false)
            dispatch("loadSvzs");
        } catch (error) {
            console.log(error)
            commit("setSvzLoading",false)
        }
    },
    async loadSvzs({commit,getters}) {
        try {
            commit("setSvzLoading",true)
            const svzResponse = (await axios.get(`svz/institute/${getters.getSelectedInstitute.id}`)).data;
            commit("setSvz", svzResponse);
            commit("setSvzLoading",false)
        } catch (error) {
            console.log(error)
            commit("setSvzLoading",false)
        }
    },
    async loadSvz({commit},svzId) {
        try {
            commit("setSvzLoading",true)
            const svzResponse = (await axios.get(`svz/${svzId}`)).data;
            commit("setSelectedSvz", svzResponse);
            commit("setSvzLoading",false)
        } catch (error) {
            console.log(error)
            commit("setSvzLoading",false)
        }
    },
    async editSvz({commit,dispatch}, svz) {
        try {
            commit("setSvzLoading",true)
            const updatedSvz = (await axios.put(`svz/${svz.id}`, svz)).data;
            commit("setSelectedSvz",updatedSvz)
            commit("setSvzLoading",true)
            dispatch("loadSvzs");
        }catch (e) {
            console.log(e)
        }
    },
    async removeSvz({commit,dispatch}, svzId) {
        try {
            commit("setSvzLoading",true)
            await axios.delete(`svz/${svzId}`);
            commit("setSelectedSvz",null)
            commit("setSvzLoading",true)
            dispatch("loadSvzs");
        }catch (e) {
            console.log(e)
        }
    }
}
const getters = {
    getSvzs(state) {
        return state.svzs;
    },
    getSvzLoading(state){
        return state.svzLoading;
    },
    getSelectedSvz(state){
        return state.selectedSvz;
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}