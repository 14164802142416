<template>
  <div class="text-center">
    <v-dialog v-model="show"  width="500">
      <v-card>
        <v-card-title class="success-dialog-title">
          {{ success.title }}
        </v-card-title>

        <v-card-text>
          {{ success.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="ok">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    props: {
      value: Boolean,
      success: null,
    },
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      ok() {
        this.$store.dispatch("changeActiveSuccess", false)
        this.show = false
      },
    },
  }
</script>

<style scoped>
  .error-dialog-title {
    color: white;
    font-size: 26px;
  }

  .v-card__text {
    padding: 24px !important;
    font-size: 18px;
  }

  .success-dialog-title {
    background-color: #006400 !important;
    color: #fff;
  }
</style>