<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="yes"
          >
            Ja
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="no"
          >
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-revision-dialog :dialog="revisionDialog" @save="yesWithRevision"></app-revision-dialog>

  </div>
</template>
<script>
import AppRevisionDialog from "./Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog},
  props: {
    text: {type: String}, title: {type: String}, item: {type: Object}, revision: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      dialog: true,
      revisionDialog: false,
    }
  },
  methods: {
    yes() {
      if (this.revision) {
        this.revisionDialog = true;
      }
      else{
        this.$emit("approve", this.item);
      }

    },
    yesWithRevision(revision) {
      this.$emit("approve", {data:this.item,revision:revision});
    },
    no() {
      this.$emit("decline", this.item);
    }
  }
}
</script>