import axios from "axios";

const state = {
    user: [],

}
const mutations = {

    setUsers(state, value) {
        state.user = value;
    },
}
const actions = {
    async loadUsers({
                        commit
                    }, params) {
        try {
            var userResponse = (await axios.get(`user`, {
                params: params
            })).data;

            var users = []
            userResponse.forEach(async (user) => {


                users.push({
                    id: user.id,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    company: user.company,
                    password: "",
                    role: user.role,
                })
            })
            commit("setUsers", users);
        } catch (error) {
            console.log(error)
        }
    },
    async addUser({
                      dispatch
                  }, addItem) {
        try {
            await axios.post(`user`, addItem.user, {
                headers: {
                    "Revision-Change-Reason-Type": addItem.revision.changeReasonType,
                    "Revision-Description": addItem.revision.description,
                }
            });

        } catch (err) {
            dispatch("createError", err.response)
        }
        dispatch("loadUsers");
    },
    async editUser({
                       dispatch
                   }, addItem) {
        try {
            await axios.put(`user/${addItem.user.id}`, addItem.user, {
                headers: {
                    "Revision-Change-Reason-Type": addItem.revision.changeReasonType,
                    "Revision-Description": addItem.revision.description,
                }
            });
            dispatch("loadUsers");
        } catch (err) {
            dispatch("createError", err.response)
        }
    },
    async deleteUser({
                         dispatch
                     }, deleteItem) {
        try {


            await axios.delete(`/user/${deleteItem.user.id}`, {
                headers: {
                    "Revision-Change-Reason-Type": deleteItem.revision.changeReasonType,
                    "Revision-Description": deleteItem.revision.description,
                }
            });
            dispatch("loadUsers");
        } catch (err) {
            dispatch("createError", err.response)
        }

    }
}
const getters = {
    getUsers(state) {
        return state.user;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}