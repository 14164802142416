import axios from "axios";
const state = {
    globalMail: null
}
const mutations = {
    setGlobalMail(state, value) {
        state.globalMail = value;
    },
}
const actions = {
    async loadGlobalMail({
        commit
    }) {
        try {
            var globalMailResponse = (await axios.get(`globalMail`)).data;

            commit("setGlobalMail", globalMailResponse);
        } catch (error) {
            console.log(error)
        }
    },
    async editGlobalMail({
        dispatch
    }, editItem) {
        await axios.put(`globalMail`, editItem.globalMail, {
            headers: {
                "Revision-Change-Reason-Type": editItem.revision.changeReasonType,
                "Revision-Description": editItem.revision.description,
            }
        });
        dispatch("loadGlobalMail");
    }
}
const getters = {
    getGlobalMail(state) {
        return state.globalMail;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}