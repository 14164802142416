import axios from "axios";
const state = {
    scan: [],
    securityStatusTexts: {
        GENUINE: {
          text: "Das Dokument konnte erfolgreich verifiziert werden",
          color: "#008000"
        },
        ERROR: {
          text: "Die Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
          color: "#aa0000"
        },
        FAILURE: {
          text: "Die Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
          color: "#aa0000"
        },
        WARNING: {
          text: "Einige Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
          color: "#aa0000"
        },
        ADMIN: {
            text: "Das Dokument wurde manuell von einem Admin freigegeben obwohl einige Sicherheitsprüfungen nicht erfolgreich durchgeführt wurden.",
            color: "#ff6600"
          },
        UNKNOWN: {
          text: "Das Dokument ist unbekannt. Bitte prüfen Sie das Legitimationsdokument sorgfältig, bevor Sie die Legitimation vornehmen",
          color: "#00aa00"
        },
        EXPIRED: {
          text: "Das Dokument ist abgelaufen. Es kann nicht zur Legitimation verwendet werden.",
          color: "#ff0000"
        },
        SAMPLE: {
          text: "Dies ist ein Testdokument und kann daher nicht zur Legitimation verwendet werden.",
          color: "#ff0000"
        },
      },
}
const mutations = {

    setScan(state, events) {
        state.scan = events;
    },

    addScan(state, newScan){
        var existingItems = state.scan.map(item => item.id)
        if(existingItems.indexOf(newScan.id) == -1){
            state.scan.unshift(newScan)
        }else{
            var existingScan = state.scan.find(obj => {
                return obj.id === newScan.id
            })
            var existingIndex = state.scan.indexOf(existingScan)
            var existingImages = existingScan.images
          
            newScan.images = existingImages
            state.scan[existingIndex].id = newScan.id ? newScan.id : "",
            state.scan[existingIndex].user = newScan.user ? newScan.user : "",
            state.scan[existingIndex].status = newScan.status ? newScan.status : "",
            state.scan[existingIndex].dtype = newScan.scanData.dtype ? newScan.scanData.dtype : "",
            state.scan[existingIndex].persontype = newScan.persontype ? newScan.persontype : "",
            state.scan[existingIndex].images = existingImages,
            state.scan[existingIndex].security = newScan.security ? newScan.security : "",
            state.scan[existingIndex].scanData = newScan.scanData
            state.scan[existingIndex].pdfData = newScan.pdfData

        }
    },

    clearScans() {
        state.scan = []
    },
}

const actions = {
    setFieldsAsOptional({commit, dispatch}, scanID){
        axios.put(`/scan/${scanID}/setFieldsAsOptional`).then(resp =>{
            commit("addScan", resp.data)
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    downloadPdf({
        // eslint-disable-next-line no-unused-vars
        commit, dispatch
    },scan) {
        axios.get(`scan/${scan.id}/pdf/name`).then(name => {
            var filename = name.data
            axios.get(`scan/${scan.id}/pdf`, { responseType: 'blob'}).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); 
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                //console.log(err.response)
                //console.log(err)
                dispatch("createError", err.response)
            })
        }).catch(err => {
            //console.log(err.response)
            //console.log(err)
            dispatch("createError", err.response)
        })
        
    },
    downloadOriginalData({
        // eslint-disable-next-line no-unused-vars
        commit, dispatch
    },scan) {

            axios.get(`scan/${scan.id}/original-data`, { responseType: 'blob'}).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',"original_data.json"); 
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                dispatch("createError", err.response)
            })
        
    },
  
    async loadScans({
        commit
    },params) {
        try {
            var scanResponse = (await axios.get(`institute/${params}/scan`)).data;

            scanResponse.sort((a, b) => (parseInt(a.createdAt) > parseInt(b.createdAt)) ? 1 : ((parseInt(b.createdAt) >
            parseInt(a.createdAt)) ? -1 : 0))
            console.log(scanResponse)
            scanResponse.forEach((scan) => {
                try {

                    var images = []
                    scan.images.sort((a, b) => (parseInt(a.rank) > parseInt(b.rank)) ? 1 : ((parseInt(b.rank) >
                        parseInt(a.rank)) ? -1 : 0))
                    scan.images.forEach((image) => {
                        var picture = {
                            id: image.id,
                            rank: image.rank,
                            createdAt: image.created_at,
                            baseImage: null,
                            processedImage: null,
                            baseImagePath: image.baseImagePath,
                            processedImagePath: image.processedImagePath,
                        }
                      
                        images.push(picture)
                    })
                   
                    var newScan = {
                        id: scan.id ? scan.id : "",
                        user: scan.user ? scan.user : "",
                        status: scan.status ? scan.status : "",
                        createdAt: scan.createdAt ? scan.createdAt : "",
                        dtype: scan.scanData.dtype ? scan.scanData.dtype : "",
                        persontype: scan.persontype ? scan.persontype : "",
                        pdfData: scan.pdfData ? scan.pdfData : undefined,
                        images: images,
                        security: scan.security ? scan.security : "",
                        //imagesRef: scan.images,
                        scanData: scan.scanData ? scan.scanData : "",
                    }
                    commit("addScan", newScan);

                } catch (x) {
                    console.log(x)
                }


            })
        } catch (error) {
            console.log(error)
        }
    },
    async loadVisibleImages({
        commit
    }, params) {
        var filteredItems = params.filteredItems
        for(var i = (params.page - 1) * params.itemsPerPage; i < params.page * params.itemsPerPage; i++){
            if(filteredItems[i]){
               var scan = filteredItems[i]
               if(scan.images[0] && !scan.images[0].processedImage){
                   await axios.get(scan.images[0].processedImagePath).then(data => {
                scan.images[0].processedImage = data.data
            })
            commit("addScan", scan) 
               }
            }
        }
    },

    async createScans({
        dispatch
    }, scanDTO) {
        await axios.post(`scans`, scanDTO,{
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }}).then(function (result) {
                dispatch("createSuccess", {title: "Erfolg!", message:`Der Scan Aufrag wurde bearbeitet [Erfolgreich:${result.data.successScans.length}| Fehlgeschlagen:${result.data.failedScans.length}|Duplikate:${result.data.duplicateScans.length}]`})
            }).catch(err => {
                dispatch("createError", err.response)
            });
            
    },
    async addScan({
        dispatch
    }, scan) {
        await axios.post(`scan`, scan);
        dispatch("loadScans");
    },
    async editScan({
        dispatch
    }, item) {
        var scan = item.scan
     
        await axios.put(`scan/${scan.id}?validateonly=true`, scan).then(function (){
            dispatch("changeActiveError", false)
            dispatch("createSuccess", {title: "Speichern erfolgreich", message:"Die Änderungen wurden erfolgreich übernommen."})
            }).catch(err => {
            dispatch("createError", err.response)
            })
    },
    async deleteScan({
        dispatch
    }, id) {
        await axios.delete(`/scan/${id}`).then(function (){
            dispatch("changeActiveError", false)
            }).catch(err => {
            dispatch("createError", err.response)
            })
        dispatch("loadScans");
    },
    async loadImages({
        // eslint-disable-next-line no-unused-vars
        dispatch
    },scan) {
        scan.images.forEach(async (image) => {
            if(image.processedImage == null){
                image.processedImage = (await axios.get(image.processedImagePath)).data;
            } 
            if(image.baseImage == null){
                image.baseImage = (await axios.get(image.baseImagePath)).data;
            }                                             
          })
    },

    async setDType({
        // eslint-disable-next-line no-unused-vars
        commit
    },data) {
        data.scan.scanData.dtype.fieldValue = data.dtype
        commit("addScan", data.scan);
    },
    async clearScans({
        commit
    }) {
        commit("clearScans")
    },
}

const getters = {
    getScans(state) {
        return state.scan;
    },
    getSecurityStatusTexts(state){
        return state.securityStatusTexts
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}