<template>
    <div>
        <router-view />
        <app-svz-table :items="svzs" heading="Svz" @delete="deleteItem" className="Svz" @update="update"
                                       @add="add" :headers="headers" newItemText="Neue Svz" />
    </div>
</template>
<script>
import BaseTable from "@/components/Svz/SvzTable.vue";
export default {
    data() {
        return {
            headers: [{
                text: 'svzValue',
                align: 'start',
                sortable: false,
                value: 'svzValue',
            },
                {
                    text: 'shortTitle',
                    value: 'shortTitle'
                },
                {
                    text: 'shortDescription',
                    value: 'shortDescription'
                },
                {
                    text: 'svzType',
                    value: 'svzType'
                },
                {
                    text: 'refSvz',
                    value: 'refSvz'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                },
            ],
        }
    },
    components: {
        appSvzTable: BaseTable
    },
    computed: {
        svzs() {
            return this.$store.getters.getSvzs;
        },
        selectedInstituteID(){
            return this.$route.params.instituteID
        }
    },
    watch:{
        selectedInstituteID(){
            this.loadSvzs()
        }
    },
    methods: {
        async loadSvzs(){
            await this.$store.dispatch("loadSelectedInstitute",this.selectedInstituteID)
            await this.$store.dispatch("loadSvzs", this.selectedInstituteID);
        },
        update(item) {
            this.$router.push({
                name: "SvzUpdate",
                params: {
                    svzID: item.id
                }
            });
        },
        deleteItem(item) {
            this.$router.push({
                name: "SvzDelete",
                params: {
                    svzID: item.id
                }
            });
        },
        add() {
            this.$router.push({
                name: "SvzCreate",
            });
        }
    },
    created() {
        this.loadSvzs()
    }
}
</script>