import axios from "axios";
const state = {
    institut: [],
    templates: [],
    selectedInstitute:null

}
const mutations = {

    setinstitut(state, events) {
        state.institut = events;
    },
    setTemplates(state, events) {
        state.templates = events;
    },
    setSelectedInstitute(state,selectedInstitute){
        state.selectedInstitute = selectedInstitute
    }
}
const actions = {
    async loadSelectedInstitute({commit},instituteID){
        try {
            const instituteResponse = (await axios.get(`institute/${instituteID}`)).data;
            commit("setSelectedInstitute",instituteResponse)
        }catch (e)
        {
            commit("setSelectedInstitute",null)
            console.log(e)
        }
    },
    async loadInstitutes({
        commit
    }, params) {
        try {
            var institutResponse = (await axios.get(`institute`, {
                params: params
            })).data;

            var institute = []
            var templates = []
            institutResponse.forEach(async (mandant) => {
                var logo = null
                try {
                    logo = (await axios.get(mandant.logoRef)).data
                } catch (x) {
                    console.log(x)

                }

                institute.push({
                    id: mandant.id,
                    name: mandant.name,
                    created_at: mandant.created_at,
                    region: mandant.region,
                    institute_nr: mandant.institute_nr,
                    vrz: mandant.vrz,
                    status: mandant.status,
                    instituteToken: mandant.instituteToken,
                    instituteAccessDomain: mandant.instituteAccessDomain,
                    logo: logo,
                    logoRef: mandant.logoRef,
                    autoLegitimize:mandant.autoLegitimize,
                    ignoreNameSuffix:mandant.ignoreNameSuffix,
                    scanDataTitleCase:mandant.scanDataTitleCase,
                    isTemplate: mandant.isTemplate,
                    captureValidUntil: mandant.captureValidUntil,
                    captureBirthName: mandant.captureBirthName,
                    updateGroup: mandant.updateGroup,
                    template: mandant.template,
                    osp: mandant.osp,
                    mailing: mandant.mailing,
                    deletion: mandant.deletion,
                    archiving: mandant.archiving,
                    controlChecks: mandant.controlChecks,
                    acceptanceCriteria: mandant.acceptanceCriteria,
                    fieldValidation: mandant.fieldValidation,
                    dtypeMapping: mandant.dtypeMapping,
                    reporting: mandant.reporting,
                    svzLeg: mandant.svzLeg,
                    captureAddressData:mandant.captureAddressData
                })
                if (mandant.isTemplate) {
                    templates.push({
                        name: mandant.name,
                        id: mandant.id
                    })
                }
            })
            commit("setinstitut", institute);
            commit("setTemplates", templates);
        } catch (error) {
            console.log(error)
        }
    },
    async addinstitut({
        dispatch
    }, editItem) {
        let institut = editItem.institut
        if(institut.template){
            institut.template = institut.template.id
        }
        institut.logoRef = (await axios.post("images", {
            data: institut.logo
        })).data
        institut.logo = null
        await axios.post(`institute`, institut,{
            headers:{
                "Revision-Change-Reason-Type":editItem.revision.changeReasonType,
                "Revision-Description":editItem.revision.description,
            }
        });
        dispatch("loadInstitutes");
    },
    async editinstitut({
        dispatch
    }, editItem) {
        var institut = editItem.institut
        if (editItem.newLogo) {
            institut.logoRef = (await axios.post("images", {
                data: institut.logo
            })).data
        }
        institut.logo = null
        await axios.put(`institute/${institut.id}`, institut,{
            headers:{
                "Revision-Change-Reason-Type":editItem.revision.changeReasonType,
                "Revision-Description":editItem.revision.description,
            }
        }).then(function (){
            dispatch("changeActiveError", false)
            dispatch("createSuccess", {title: "Speichern erfolgreich", message:"Die Änderungen wurden erfolgreich übernommen."})
            }).catch(err => {
            console.log(err)
            dispatch("createError", err.response)
            })
        dispatch("loadInstitutes");
    },
    async deleteinstitut({
        dispatch
    }, editItem) {
        await axios.delete(`/institute/${editItem.institut.id}`,{
            headers:{
                "Revision-Change-Reason-Type":editItem.revision.changeReasonType,
                "Revision-Description":editItem.revision.description,
            }
        });
        dispatch("loadInstitutes");

    }
}
const getters = {
    getInstitutes(state) {
        return state.institut;
    },
    getTemplates(state) {
        return state.templates;
    },
    getSelectedInstitute(state){
        return state.selectedInstitute
    },
    getInstituteById: (state) => (id) =>{
        return state.institut.find(institut => institut.id == id)
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}