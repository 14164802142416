import axios from "axios"
const state = {
    selectedRevision: [],
    revisions:[],
    revisionLoading:false
}
const mutations = {
    setSelectedRevision(state, selectedRevision) {
        state.selectedRevision = selectedRevision;
    },
    setRevisons(state, revisions) {
        state.revisions = revisions;
    },
    setRevisionLoading(state, revisionLoading){
        state.revisionLoading = revisionLoading
    }
}
const actions = {
    async loadRevision({
                           commit
                       }, revision) {
        try {
            commit("setRevisionLoading", true);
            var revisions = (await axios.get(`/revision/${revision.className}/${revision.id}`)).data;
            commit("setSelectedRevision", revisions);
            commit("setRevisionLoading", false);
        } catch (error) {
            console.log(error);
            commit("setRevisionLoading", false);
        }
    },
    async loadRevisions({
                            commit
                        }, revision) {
        try {
            commit("setRevisionLoading", true);
            var revisions = (await axios.get(`/revision/${revision.className}`)).data;
            commit("setRevisons", revisions);
            commit("setRevisionLoading", false);
        } catch (error) {
            console.log(error);
            commit("setRevisionLoading", false);
        }
    },
}
const getters = {
    getSelectedRevision(state){
        return state.selectedRevision;
    },
    getRevisions(state){
        return state.revisions;
    },
    getRevisionLoading(state){
        return state.revisionLoading;
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}