<template>  
    <div>
        <app-global-notification-delete revision @approve="deleteGlobalNotification" @decline="cancel" :title="'Benachrichtigung '+SelectedGlobalNotification.subject+' wirklich löschen?'" :text="'Benachrichtigung '+SelectedGlobalNotification.subject+' wird endgültig gelöscht?'" :item="SelectedGlobalNotification" />
    </div>
</template>
<script>
import GlobalNotificationDeleteComponent from "../../components/DialogYesNoRoute"
export default {
    components:{
        appGlobalNotificationDelete:GlobalNotificationDeleteComponent
    },
    computed:{
        GlobalNotificationID(){
            return this.$route.params.globalNotificationID
        },
        SelectedGlobalNotification(){
            return this.$store.getters.getSelectedGlobalNotification;
        }
    },
    methods:{
        updateGlobalNotification(globalNotificationID){
            this.$store.dispatch("updateGlobalNotification",globalNotificationID)
        },
        loadActualGlobalNotification(newID){
            if(newID){
                this.$store.dispatch("loadGlobalNotification",newID);
            }
        },
        deleteGlobalNotification(item){
            this.$store.dispatch("removeGlobalNotification",item);
            this.$router.push({name:"GlobalNotification"});
        },
        cancel(){
            this.$router.push({name:"GlobalNotification"});
        }
    },
    watch:{
        GlobalNotificationID(newID){
            this.loadActualGlobalNotification(newID);
        }
    },
    created(){
        this.loadActualGlobalNotification(this.GlobalNotificationID);
    }
}
</script>