<template>
  <app-reporting-config v-if="ReportingConfig" :item="ReportingConfig" @update="updateConfig" @close="close"></app-reporting-config>
</template>

<script>
import appReportingConfig from "../../components/GlobalReporting/GlobalReportingConfig.vue"
export default {
  components:{
    appReportingConfig
  },
  computed:{
    ReportingConfig(){
      return this.$store.getters.getGlobalReportingConfig;
    }
  },
  methods:{
    updateConfig(data){
      this.$store.dispatch("setGlobalReportingConfig",data)
      this.close()
    },
    close(){
      this.$router.push({name:"GlobalReporting"})
    }
  },
  created() {
    this.$store.dispatch("loadGlobalReportingConfig")
  }
}
</script>
<style scoped>

</style>