<template>
  <v-data-table :headers="headers" :items="items" :search="search">
    <template v-slot:item.enabled="{ item }">
      <template v-if="item.enabled">
        <v-icon color="green">mdi-thumb-up</v-icon>
      </template>
      <template v-else>
        <v-icon color="red">mdi-thumb-down</v-icon>
      </template>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn v-if="!disableUpdate" @click="updateItem(item)" text>
        <v-icon small class="mr-2">
          mdi-book-edit
        </v-icon>
      </v-btn>
      <v-btn @click="deleteItem(item)" text>
        <v-icon small class="mr-2">
          mdi-delete
        </v-icon>
      </v-btn>
      <template v-if="historyEnabled">
        <v-btn @click="historyItem(item)" icon>
          <v-icon small class="mr-2">
            fa-history
          </v-icon>
        </v-btn>
      </template>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    items: {
      type: Array
    },
    headers: {
      type: Array
    },
    search: {
      type: String
    },
    disableUpdate: {
      type: Boolean
    },
    historyEnabled:{
      type:Boolean,
      default:false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    updateItem(item) {
      this.$emit('updateItem', item);
    },
    historyItem(item){
      this.$emit("historyItem",item)
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  }
}
</script>