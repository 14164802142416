import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

var xhr = new XMLHttpRequest();

// Setup our listener to process completed requests
xhr.onload = function () {

  // Process our return data
  if (xhr.status >= 200 && xhr.status < 300) {
    // What do when the request is successful
    var response = JSON.parse(xhr.response);
    axios.defaults.baseURL = response.server.protocoll + '://' + response.server.server + ":" + response.server.port; //'https://api.betapp.appstasy.de'; 
    Vue.config.productionTip = false
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`

    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')

  }
};
xhr.open('GET', '/static/env.json');
xhr.send();