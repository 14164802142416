const state = {
    success: "",
    activeSuccess: true
}
const mutations = {
    setSuccess(state, success) {
        state.success = success;
    },
    setActiveSuccess(state, activeSuccess) {
     state.activeSuccess = activeSuccess;
 },
}
const actions = {
    createSuccess({
        commit
    }, success) {
        commit("setActiveSuccess", true)
        commit("setSuccess", success)
    },
    changeActiveSuccess({
     commit
 }, activeSuccess) {
     commit("setActiveSuccess", activeSuccess)
 },
}
const getters = {
    getSuccess(state) {
        return state.success
    },
    getActiveSuccess(state) {
     return state.activeSuccess
 }
}
export default {
    state,
    actions,
    mutations,
    getters
}