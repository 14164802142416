import axios from "axios";

const state = {
    countries: []
}
const mutations = {
    setCountries(state, countries) {
        state.countries = countries;
    }
}
const actions = {
    async loadCountries({
                            commit
                        }, params) {
        try {
            var userResponse = (await axios.get(`country`, {
                params: params
            })).data;

            var countries = []
            userResponse.forEach(async (country) => {

                countries.push({
                    abkuerzung: country.abkuerzung,
                    created_at: country.created_at,
                    englisch: country.englisch,
                    icao2: country.icao2,
                    icao3: country.icao3,
                    id: country.id,
                    iso_3166_A2: country.iso_3166_A2,
                    iso_3166_A3: country.iso_3166_A3,
                    kfz: country.kfz,
                    kurzform: country.kurzform,
                    nationalitaet: country.nationalitaet,
                    osplap: country.osplap,
                    osplkz: country.osplkz,
                    ospnat: country.ospnat,
                    vollform: country.vollform,
                })
            })
            commit("setCountries", countries);
        } catch (error) {
            console.log(error)
        }
    },
    async addCountry({
                         dispatch
                     }, addItem) {
        try {
            await axios.post(`country`, addItem.country, {
                headers: {
                    "Revision-Change-Reason-Type": addItem.revision.changeReasonType,
                    "Revision-Description": addItem.revision.description,
                }
            });
            dispatch("loadCountries");
        } catch (err) {
            dispatch("createError", err.response)
        }
    },
    async editCountry({
                          dispatch
                      }, editItem) {
        try {
            await axios.put(`country/${editItem.country.id}`, editItem.country, {
                headers: {
                    "Revision-Change-Reason-Type": editItem.revision.changeReasonType,
                    "Revision-Description": editItem.revision.description,
                }
            });
            dispatch("loadCountries");
        } catch (err) {
            dispatch("createError", err.response)
        }
    },
    async deleteCountry({
                            dispatch
                        }, deleteItem) {
        try {


            await axios.delete(`/country/${deleteItem.country.id}`, {
                headers: {
                    "Revision-Change-Reason-Type": deleteItem.revision.changeReasonType,
                    "Revision-Description": deleteItem.revision.description,
                }
            });
            dispatch("loadCountries");
        } catch (err) {
            dispatch("createError", err.response)
        }

    }
}
const getters = {
    getCountries(state) {
        return state.countries;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}