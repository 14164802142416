
<template>
  <app-revisions></app-revisions>
</template>
<script>
import appRevisions from "../../components/Revision/Revisions.vue"
export default {
  components:{
    appRevisions
  }
}
</script>