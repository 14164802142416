<template>
    <div>
        <router-view />
        <app-global-notification-table history-enabled @historyItem="historyItem" @history="history" :items="globalNotifications" heading="GlobalNotification" @delete="deleteItem" className="GlobalNotification" @update="update"
            @add="add" :headers="headers" newItemText="Neue GlobalNotification" />
    </div>
</template>
<script>
    import GlobalNotificationTableComponent from "../../components/BaseTable"
    export default {
        data() {
            return {
                headers: [{
                        text: 'Subject',
                        align: 'start',
                        sortable: false,
                        value: 'subject',
                    },
                    {
                        text: 'body',
                        value: 'body'
                    },
                    {
                        text: 'Enabled',
                        value: 'enabled'
                    },
                    {
                        text: 'Actions',
                        value: 'actions'
                    },
                ],
            }
        },
        components: {
            appGlobalNotificationTable: GlobalNotificationTableComponent
        },
        computed: {
            globalNotifications() {
                return this.$store.getters.getGlobalNotifications;
            },
        },
        methods: {
          historyItem(item) {
            this.$router.push({
              name: 'RevisionForId',
              params: {
                className: "globalNotification",
                id: item.id
              },
              query: {
                caller: this.$route.name
              }
            })
          },
          history() {
            this.$router.push({
              name: 'Revisions',
              params: {
                className: "globalNotification"
              },
              query: {
                caller: this.$route.name
              }
            })
          },
            update(item) {
                this.$router.push({
                    name: "GlobalNotificationUpdate",
                    params: {
                        globalNotificationID: item.id
                    }
                });
            },
            deleteItem(item) {
                this.$router.push({
                    name: "GlobalNotificationDelete",
                    params: {
                        globalNotificationID: item.id
                    }
                });
            },
            add() {
                this.$router.push({
                    name: "GlobalNotificationCreate",
                });
            }
        },
        created() {
            this.$store.dispatch("loadGlobalNotifications");
        }
    }
</script>