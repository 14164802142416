import axios from "axios";
const state = {
    logs: [],
    latestLog: 1624273500000,
    logLevelItems: [{
        text: 'Debug',
        index: 0
      }, {
        text: 'Info',
        index: 1000
      }, {
        text: 'Warning',
        index: 2000
      }, {
        text: 'Error',
        index: 3000
      }],
    requestLogs:[],
    selectedRequestLog:null
}
const mutations = {
    setLogs(state, logs) {
        state.logs = logs;
    },
    setLatestLogs(state, latestLog) {
        state.latestLog = latestLog;
    },
    setRequestLogs(state,logs){
        state.requestLogs = logs
    },
    setSelectedRequestLog(state,selectedRequestLog){
        state.selectedRequestLog = selectedRequestLog
    }
}
const actions = {
    async loadLogs({
        state, commit, dispatch
    }, params) {
        try {
            var userResponse = null

            await axios.get(`log`, {
                params: params
            }).then(function (response) {
                userResponse = response.data
            }).catch(err => {
                dispatch("createError", err.response)
            });

            var logs = []
            userResponse.forEach(async (log) => {


                logs.push({
                    additionalInfo: log.additionalInfo,
                    level: state.logLevelItems.find(x => x.index == log.level).text,
                    scan: log.scan,
                    institute: log.institute,
                    user: log.user,
                    message: log.message,
                    createdAt: log.createdAt
                })
            })
            commit("setLogs", logs);
        } catch (error) {
            console.log(error)
        }
    },
   
    async dumpLatestLog({
        commit
    }) {
            commit("setLatestLogs", 1624273500000);
    },
    async loadRequestLogs({commit},params){
        try {
            const logs = (await axios.get(`/log/request`, {
                params: params
            })).data
            commit("setRequestLogs",logs)
        }
        catch (e) {
            console.log(e)
        }

    },
    async loadRequestLog({commit},id){
        try {
            const log = (await axios.get(`/log/request/${id}`)).data
            commit("setSelectedRequestLog",log)
        }
        catch (e) {
            console.log(e)
        }

    }
}
const getters = {
    getLogs(state) {
        return state.logs;
    },
    getLogLevelItems(state) {
        return state.logLevelItems;
    },
    getLatestLog(state) {
        return state.latestLog;
    },
    getRequestLogs(state){
        return state.requestLogs
    },
    getSelectedRequestLog(state){
        return state.selectedRequestLog
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}