<template>
    <div>
        <app-svz-delete v-if="SelectedSvz" @approve="deleteSvz" @decline="cancel" :title="'SVZ Eintrag '+SelectedSvz.shortTitle+' wirklich löschen?'" :text="'SVZ Eintrag '+SelectedSvz.shortDescription+' wird endgültig gelöscht?'" :item="SelectedSvz" />
    </div>
</template>
<script>
import SvzDeleteComponent from "../../components/DialogYesNoRoute"
export default {
    components:{
        appSvzDelete:SvzDeleteComponent
    },
    computed:{
        SvzID(){
            return this.$route.params.svzID
        },
        SelectedSvz(){
            return this.$store.getters.getSelectedSvz;
        }
    },
    methods:{
        loadActualSvz(newID){
            if(newID){
                this.$store.dispatch("loadSvz",newID);
            }
        },
        deleteSvz(item){
            this.$store.dispatch("removeSvz",item.id);
            this.$router.push({name:"Svz"});
        },
        cancel(){
            this.$router.push({name:"Svz"});
        }
    },
    watch:{
        SvzID(newID){
            this.loadActualSvz(newID);
        }
    },
    created(){
        this.loadActualSvz(this.SvzID);
    }
}
</script>