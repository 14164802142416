<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-2" @click="toConfig">
            <v-icon>mdi-file-document-edit-outline</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <app-date-time-picker style="width: 100%" :value="dateTime" @change="changeDate">
          </app-date-time-picker>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getSummary">
            Report
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getFullReport">
            PDF-Report
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-container>
    <v-card v-if="summary">
      <v-card-title>Report Global</v-card-title>
      <v-card-text>
        <v-row>
          <template v-for="head in headers">
            <v-col cols="3" v-if="head.globalValue" :key="head">
              <v-card class="resultCard">
                <v-card-title>{{ head.text }}</v-card-title>
                <v-card-text>{{ globalSummary[head.globalValue] }}</v-card-text>
              </v-card>
            </v-col>
          </template>

        </v-row>


      </v-card-text>
    </v-card>
    <v-card v-if="summary">
      <v-card-title>Report Pro Institute</v-card-title>
      <v-card-text>
        <v-data-table v-if="summary"
                      :items="summary"
                      :headers="headers"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import appDateTimePicker from "../DateRangePicker.vue";

export default {
  components: {
    appDateTimePicker
  },
  props: {
    summary: {
      type: Array
    }
  },
  computed: {
    dateTime() {
      return [this.requestParams.from, this.requestParams.to]
    },
    globalSummary() {
      let result = {
        addressDataCompared: 0,
        completedAutoLegitimations: 0,
        completedLegitimations: 0,
        completedPotentialCustomers: 0,
        createdLegitimations: 0,
        deletedAfterInactivity: 0,
        deletedByUser: 0,
        uniqueUsers: 0
      };
      if(this.summary){
        this.summary.forEach(x => {
          result.addressDataCompared += x.second.addressDataCompared
          result.completedAutoLegitimations += x.second.completedAutoLegitimations
          result.completedLegitimations += x.second.completedLegitimations
          result.completedPotentialCustomers += x.second.completedPotentialCustomers
          result.createdLegitimations += x.second.createdLegitimations
          result.deletedAfterInactivity += x.second.deletedAfterInactivity
          result.deletedByUser += x.second.deletedByUser
          result.uniqueUsers += x.second.uniqueUsers
        })
      }
      return result;
    },
  },
  data() {
    return {
      requestParams: {
        "from": "",
        "to": "",
      },
      headers: [{
        text: 'Institute',
        align: 'start',
        value: 'first.name',
      },
        {
          text: 'AddressDaten verglichen',
          value: 'second.addressDataCompared',
          globalValue: 'addressDataCompared'
        },
        {
          text: 'Automatisch Verarbeitet',
          value: 'second.completedAutoLegitimations',
          globalValue: 'completedAutoLegitimations'
        },
        {
          text: 'Legitimationen abgeschlossen',
          value: 'second.completedLegitimations',
          globalValue: 'completedLegitimations'
        },
        {
          text: 'Interessenten erstellt',
          value: 'second.completedPotentialCustomers',
          globalValue: 'completedPotentialCustomers'
        },
        {
          text: 'Legitimationen erstellt',
          value: 'second.createdLegitimations',
          globalValue: 'createdLegitimations'
        },
        {
          text: 'Gelöscht nach inaktivität',
          value: 'second.deletedAfterInactivity',
          globalValue: 'deletedAfterInactivity'
        },
        {
          text: 'Vom Benutzer gelöscht',
          value: 'second.deletedByUser',
          globalValue: 'deletedByUser'
        },
        {
          text: 'Eindeutige Benutzer',
          value: 'second.uniqueUsers',
          globalValue: 'uniqueUsers'
        }
      ],
    }
  },
  methods: {
    toConfig() {
      this.$emit("navigateToConfig");
    },
    changeDate(date) {
      this.requestParams.from = date.validFrom
      this.requestParams.to = date.validTill
    },
    getFullReport() {
      this.$emit("loadPdfReport", this.requestParams)
    },
    getSummary() {
      this.$emit("loadSummary", this.requestParams)
    },
  }
}
</script>
<style scoped>
.resultCard {
  text-align: center;
}
</style>