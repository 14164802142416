

<template>
    <v-card>
        <v-tabs
                background-color="primary"
                centered
                icons-and-text
        >
            <v-tabs-slider></v-tabs-slider>
            <v-tab :to="ErrorLogPath"  >
                Error Log
                <v-icon>mdi-alert-circle-outline</v-icon>
            </v-tab>

            <v-tab :to="RequestLogPath" >
                Request Log
                <v-icon>mdi-routes</v-icon>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item value="ErrorLog">
                <v-card flat>
                    <app-error-log></app-error-log>
                </v-card>
            </v-tab-item>
            <v-tab-item value="RequestLog">
                <v-card flat>
                    <app-request-log ></app-request-log>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>
<script>
import ErrorLog from "@/components/Log/ErrorLog.vue";
import RequestLog from "@/components/Log/RequestLog.vue";
export default {
    components:{
        appErrorLog:ErrorLog,
        appRequestLog:RequestLog
    },
    props:{
        tab:{
            required:true,
            type:String
        },
        RequestLogPath:{
            required: true,
            type:Object
        },
        ErrorLogPath:{
            required: true,
            type:Object
        },
    },
    methods:{
        changeTab(tab){
            this.$emit("changeTab",tab)
        }
    }
}
</script>
<style scoped>

</style>