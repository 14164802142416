<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Event aktualisieren</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save()">
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card class="dialog-card">
            <v-list three-line subheader>
              <v-subheader>Allgemein</v-subheader>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Name" v-model="item.subject"
                                :rules="[v => !!v || 'Name ist erforderlich']" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <app-date-time-picker :value="dateTime" @change="changeDate">
                  </app-date-time-picker>
                </v-col>
                <v-col cols="6">
                  <v-combobox v-model="item.level" :items="levels" label="Level"
                              placeholder="Feld auswählen..."></v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete label="Institut ID" v-model="item.instituteId" :items="institutes"
                                  item-text="name" item-value="id"></v-autocomplete>
                </v-col>
              </v-row>
            </v-list>
            <v-divider></v-divider>
            <v-list three-line subheader>
              <v-subheader>Sonstiges</v-subheader>
              <v-row>
                <v-col cols="12">
                  <v-textarea label="Beschreibung" v-model="item.body"
                              hint="Beschreibung für das Event"></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-checkbox v-model="item.enabled" label="aktivert"></v-checkbox>
                </v-col>
              </v-row>
            </v-list>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </v-row>
</template>
<script>
import DateTimePickerComponent from '../DateRangePicker'
import AppRevisionDialog from "../Revision/RevisionDialog.vue";

export default {
  components: {
    AppRevisionDialog,
    appDateTimePicker: DateTimePickerComponent
  },
  props: ["item"],
  data() {
    return {
      revisionDialog: false,
      dialog: true,
      valid: true,
      levels: ["INFO", "WARNING", "ERROR"],
    }
  },
  methods: {
    save() {
      if (this.validate()) {
        this.revisionDialog =true
      }
    },
    saveWithRevision(revision) {
      this.$emit('update', {globalNotification: this.item, revision: revision});
      this.revisionDialog = false
    },
    cancel() {
      this.$emit('cancel');
    },
    validate() {
      return this.$refs.form.validate()
    },
    changeDate(date) {
      this.item.validFrom = date.validFrom
      this.item.validTill = date.validTill
    }
  },
  computed: {
    dateTime() {
      return [this.item.validFrom, this.item.validTill]
    },
    institutes() {
      return this.$store.getters.getInstitutes
    },
  },
  created() {
    this.$store.dispatch("loadInstitutes")
  }
}
</script>