<template>
    <div>
        <router-view />
        <app-log @changeTab="tabChange" :error-log-path="{name:'Log',params:{type:'ErrorLog'}}" :request-log-path="{name:'Log',params:{type:'RequestLog'}}" :tab="tab"></app-log>
    </div>
    </template>

<script>
import Log from "@/components/Log/Log.vue";

export default {
    components: {
        appLog: Log
    },

    methods: {
        tabChange(tab) {
            this.$router.push({
                name: "Log", params: {
                    type: tab
                }
            })
        }
    },
    computed:{
        tab(){
            return this.$route.params.type
        }
    }
}
</script>

<style scoped>

</style>