<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card class="d-flex flex-column flex-grow-1" height="100vh">
        <v-toolbar
            max-height="64px"
            dark
            color="primary"
        >
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                icon
                @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <slot></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: {
      required: true,
      type: Boolean
    },
    title: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>