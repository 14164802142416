<template>
    <div>
        <app-global-notification-create @save="createNewGlobalNotification" @cancel="cancel" />
    </div>
</template>
<script>
    import GlobalNotificationCreateComponent from "../../components/GlobalNotification/GlobalNotificationCreate"
    export default {
        components: {
            appGlobalNotificationCreate: GlobalNotificationCreateComponent
        },
        methods: {
            createNewGlobalNotification(globalNotification) {
                this.$store.dispatch("addGlobalNotification", globalNotification);
                this.$router.push({
                    name: "GlobalNotification"
                });
            },
            cancel() {
                this.$router.push({
                    name: "GlobalNotification"
                });
            },
        },
        computed: {},
    }
</script>