<template>
  <v-card color="" flat tile height="100vh" width="100vw">
    <v-toolbar dark color="primary">
      <v-app-bar-nav-icon color="secondary" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{title}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn tile @click="logout" class="top-menu-buttons">
        Abmelden
        <v-icon right>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" :to="item.link">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}<v-badge style="margin-left:5px" v-if="item.badge && newErrors != 0"
                :content=newErrors></v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <router-view />
    <SuccessDialog v-model="successDialog" :success="success" />
    <ErrorDialog v-model="errorDialog" :error="error" />
  </v-card>
</template>

<script>
  import SuccessDialog from "../components/Scans/SuccessDialog"
  import ErrorDialog from "../components/Scans/ErrorDialog"
  export default {
    data() {
      return {
        errorDialog: false,
        successDialog: false,
        drawer: null,
        items: [{
            title: 'Mandanten',
            icon: 'mdi-office-building',
            link: "/home/mandanten"
          },
          {
            title: 'Behördenmapping',
            icon: 'mdi-office-building-outline',
            link: "/home/behoerdenmapping"
          },
          {
            title: 'Ländertabelle',
            icon: 'mdi-flag',
            link: "/home/laendertabelle"
          },
          {
            title: 'Nutzerverwaltung',
            icon: 'mdi-account',
            link: "/home/nutzerverwaltung"
          },
          {
            title: 'Log',
            icon: 'mdi-bug',
            badge: 'true',
            link: {name:'Log',params:{type:'ErrorLog'}}
          },
          {
            title: 'Einstellungen',
            icon: 'mdi-cog',
            link: "/home/einstellungen"
          },
          {
            title: 'Scan anlegen',
            icon: 'mdi-cog',
            link: "/home/scan"
          },
          {
            title: 'Globale Benachrichtigungen',
            icon: 'mdi-bell',
            link: "/home/notification"
          },
          {
            title: 'Globale Mail',
            icon: 'mdi-email',
            link: "/home/globalmail"
          },
          {
            title: 'Globale Reporting',
            icon: 'fa-file-pdf',
            link: {name:"GlobalReporting"}
          },
          {
            title: 'Rechnungserstellung',
            icon: 'fa-file-pdf',
            link: "/home/invoice"
          },
        ],
      }
    },
    computed: {
      title() {
        return this.$route.name;
      },
      newErrors() {
        var value = this.$store.getters.getLatestLog
        if (localStorage && localStorage.timestamp && localStorage.timestamp < value) {
          return 1
        } else {
          return 0
        }
      },
      success() {
        return this.$store.getters.getSuccess
      },
      error() {
        return this.$store.getters.getError
      },
    },
    watch: {
      error() {
        this.errorDialog = true
      },
      success() {
        this.successDialog = true
      },
    },
    methods: {
      logout() {
        this.$store.dispatch("logout")
      },
      getLatestLog() {
        //this.$store.dispatch("loadLatestLogs")
      }
    },
    created() {
      //    this.getLatestLog() // first run
      //     this.interval = setInterval(this.getLatestLog, 2000)
    },
    components: {
      ErrorDialog,
      SuccessDialog
    }
  }
</script>
<style scoped>
  .top-menu-buttons {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: white !important;
  }
</style>