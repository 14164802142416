import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/login'
import institutes from '@/store/institut'
import users from '@/store/user'
import password from '@/store/password'
import country from '@/store/country'
import issuers from '@/store/issuers'
import scan from '@/store/scan'
import error from '@/store/error'
import success from '@/store/success'
import logs from '@/store/logs'
import reports from '@/store/reports'
import version from '@/store/version'
import globalNotification from '@/store/globalNotification'
import axios from "axios";
import globalMail from "@/store/globalMail"
import svz from "@/store/svz";
import revision from "@/store/revision"
import invoice from '@/store/invoice'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : "",
    errorCode : null,
    errorData: null
  },
  mutations: {
    setToken(state,token){
      state.token = token
      localStorage.setItem('token', token)
    },
    setErrorCode(state,errorCode){
      state.errorCode = errorCode
    },
    setErrorData(state,errorData){
      state.errorData = errorData
    },
  },
  actions: {
    async loginUser({commit},params) {
      try {
        commit("setErrorCode", null)
        var token = (await axios.post(`login`,params).catch(err => {
          console.log(err.response)
          commit("setErrorCode", err.response.status)
          commit("setErrorData", err.response.data)
        })).data;
          commit("setToken", token)
      } catch (error) {
        console.log(error)
      }
    }
  },
  getters:{
    getToken(state){
      return state.token
    },
    getErrorCode(state){
      return state.errorCode
    },
    getErrorData(state){
      return state.errorData
    }
  },
  modules: {
    login,
    institutes,
    users,
    password,
    country,
    issuers,
    scan,
    error,
    success,
    logs,
    reports,
    version,
    globalNotification,
    globalMail,
    svz,
    invoice,
    revision
  }
})
