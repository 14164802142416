<template>
  <v-app>

    <v-main>
      <template v-if="!isLoginPending">
        <template v-if="isUserLoggedin">
          <Home />
        </template>
        <template v-else>
          <app-login></app-login>
        </template>
      </template>
      <template v-else>
        <v-overlay :value="true">
          <v-progress-circular indeterminate size="64">Starte App</v-progress-circular>
        </v-overlay>
      </template>
    </v-main>
  </v-app>
</template>

<script>
  import Login from "./views/Login"
  import Home from "./views/Home"
  export default {
    name: 'App',

    components: {
      appLogin: Login,
      Home
    },
    created() {
      this.$store.dispatch("setLastActivity")
      this.interval = setInterval(this.checkForAutoLogout, 1000)
      this.$store.dispatch("relogin");
    },
    computed: {
      isUserLoggedin() {
        return this.$store.getters.isUserLoggedin;
      },
      isLoginPending() {
        return this.$store.getters.isLoginPending;
      }
    },
    watch: {
      isUserLoggedin(isLoggedIn) {
        if (isLoggedIn) {
          //this.$router.push('/home/mandanten')
        }
      }
    },
    mounted() {
    document.addEventListener("mouseup", e => {
      // eslint-disable-next-line no-unused-vars
      let m = `x: ${e.clientX} | y: ${e.clientY}`;
      this.$store.dispatch("setLastActivity")
    })
  },
    methods: {
      logout() {
        this.$store.dispatch("logout")
      },
      checkForAutoLogout() {
          console.log("Check logout")
        var lastActivity = this.$store.getters.getLastActivity;
        var currentTime = Date.now();
        var acceptedDifference = 1000 * 60 * 15; // modify last number to change Interval in Minutes
        var timeLeftUntilLogout = lastActivity + acceptedDifference - currentTime
        //console.log(timeLeftUntilLogout)
        if (timeLeftUntilLogout <= 0) {
          console.log("LOGOUT")
          this.$store.dispatch("logout")
          this.$store.commit("setErrorCode", 100001)
        }
      },
    },

    data: () => ({
      //
    })
  };
</script>