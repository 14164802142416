

<template>
    <v-dialog v-model="infoDialog" max-width="600px">
        <v-card>
            <v-card-title class="primary dialog-title headline">Informationen</v-card-title>
            <v-card-text>
                <v-card>
                    <v-card-title>Request Body</v-card-title>
                    <v-card-text>
                        {{item.requestBody}}
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title>Response Body</v-card-title>
                    <v-card-text>
                        {{item.responseBody}}
                    </v-card-text>

                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Abbrechen</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
  export default{
      data() {
          return {
              infoDialog:true
          }
      },
      methods:{
          close(){
              this.$emit("close")
          }
      },
      props:{
          item:{
              required:true,
              type:Object
          }
      }
  }
</script>
<style scoped>

</style>