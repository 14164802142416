import axios from "axios"
const state = {
  loggedIn: false,
  loginPending: false,
  user: null,
  lastActivity: undefined,
}
const mutations = {
  setLoggedIn(state, loggedIn) {
    state.loggedIn = loggedIn;
    state.loginPending = false;
  },
  setLoginPending(state, penidingstate) {
    state.loginPending = penidingstate
  },
  setUser(state, user) {
    state.user = user
  },
  setLastActivity(state, lastActivity) {
    state.lastActivity = lastActivity
  }
}
const actions = {
  async relogin({
    commit
  }) {
    if (localStorage.getItem("token")) {
      commit("setLoginPending", true);
      axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`
      try {
        await axios.get('/me').then(response => {
          commit("setUser", response.data)
      })
        commit("setLoggedIn", true);
      } catch (error) {
        commit("setLoggedIn", false);
        console.log("logout!")
        localStorage["token"] = null;
      }
    }
  },
  async logout({
    commit
  }) {
    commit("setLoggedIn", false);
    commit("setToken", null)
  },
  async setLastActivity({
    commit
  }) {
    commit("setLastActivity", Date.now());
  },
}
const getters = {
  isUserLoggedin(state) {
    return state.loggedIn;
  },
  isLoginPending(state) {
    return state.loginPending;
  },
  getUser(state) {
    return state.user;
  },
  getLastActivity(state){
    return state.lastActivity;
  }
}
export default {
  state,
  actions,
  mutations,
  getters
}