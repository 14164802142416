import axios from "axios";

const state = {
    globalNotifications: [],
    selectedGlobalNotification: null,
}
const mutations = {
    setGlobalNotifications(state, value) {
        state.globalNotifications = value;
    },
    setGlobalNotification(state, value) {
        state.selectedGlobalNotification = value;
    },

}
const actions = {
    async loadGlobalNotifications({
                                      commit
                                  }, params) {
        try {
            var globalNotificationResponse = (await axios.get(`globalNotification`, {
                params: params
            })).data;

            commit("setGlobalNotifications", globalNotificationResponse);
        } catch (error) {
            console.log(error)
        }
    },
    async addGlobalNotification({
                                    dispatch
                                }, addItem) {
        try {
            await axios.post(`globalNotification`, addItem.globalNotification, {
                headers: {
                    "Revision-Change-Reason-Type": addItem.revision.changeReasonType,
                    "Revision-Description": addItem.revision.description,
                }
            });
            dispatch("loadGlobalNotifications");
        } catch (err) {
            dispatch("createError", err.response)
        }
    },
    async loadGlobalNotification({
                                     commit
                                 }, id) {
        try {

            var globalNotification = (await axios.get(`/globalNotification/${id}`)).data;
            commit("setGlobalNotification", globalNotification);
        } catch (error) {
            console.log(error);
        }
    },
    async editGlobalNotification({
                                     dispatch
                                 }, editItem) {
        try {
            await axios.put(`globalNotification/${editItem.globalNotification.id}`, editItem.globalNotification, {
                headers: {
                    "Revision-Change-Reason-Type": editItem.revision.changeReasonType,
                    "Revision-Description": editItem.revision.description,
                }
            });
            dispatch("loadGlobalNotifications");
        } catch (err) {
            dispatch("createError", err.response)
        }
    },
    async removeGlobalNotification({
                                       dispatch
                                   }, deleteItem) {
        try {
            await axios.delete(`/globalNotification/${deleteItem.data.id}`, {
                headers: {
                    "Revision-Change-Reason-Type": deleteItem.revision.changeReasonType,
                    "Revision-Description": deleteItem.revision.description,
                }
            });
            dispatch("loadGlobalNotifications");
        } catch (err) {
            dispatch("createError", err.response)
        }
    }
}
const getters = {
    getGlobalNotifications(state) {
        return state.globalNotifications;
    },
    getSelectedGlobalNotification(state) {
        return state.selectedGlobalNotification;
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}