<template>
  <v-container>
    <v-card class="institute-basic-wrapper">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Bezeichnung
            </th>
            <th class="text-left">
              Wert
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Mail Server</td>
            <td>
              <v-text-field v-model="item.mailServer" type="text" label="Mail Server">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Mailserver mit Port</td>
            <td>
              <v-text-field v-model="item.mailPort" type="number" label="Port">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Authentifizierung aktivert</td>
            <td>
              <v-checkbox v-model="item.auth"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Versendender Benutzer</td>
            <td>
              <v-text-field :disabled="!item.auth" v-model="item.username" type="text" label="Mail User">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Versendender Benutzer password</td>
            <td>
              <v-text-field :disabled="!item.auth" type="password" v-model="item.password" label="Mail Password">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Versender E-Mail Adresse</td>
            <td>
              <v-text-field v-model="item.mailFrom" type="text" label="Mail Adress">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>StartTLS Aktiviert</td>
            <td>
              <v-checkbox v-model="item.starttlsEnable"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>StartTLS erforderlich</td>
            <td>
              <v-checkbox v-model="item.starttlsRequired"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Debug aktivieren</td>
            <td>
              <v-checkbox v-model="item.debug"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Email Protokoll</td>
            <td>
              <v-combobox v-model="item.protocol" :items="mailProtocol" label="Protokoll"
                          placeholder="Protokol wählen"></v-combobox>
            </td>
          </tr>
          </tbody>
          <v-btn @click="save()" color="primary">save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <p>Die Änderungen werden erst bei einem Serverneustart übernommen.</p>
        </template>
      </v-simple-table>
    </v-card>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </v-container>
</template>

<script>
import AppRevisionDialog from "../Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog},
  props: {
    item: {}
  },
  data() {
    return {
      revisionDialog: false,
      mailProtocol: [
        "SMTP", "SMTPS"
      ]
    }
  },
  created() {
  },


  computed: {},

  methods: {
    save() {
      this.revisionDialog = true
    },
    saveWithRevision(revision) {
      this.$emit("update", {globalMail:JSON.parse(JSON.stringify(this.item)),revision:revision})
      this.revisionDialog = false
    },
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "MailConfig"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
  },
}
</script>

<style scoped>
.institute-basic-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  margin-bottom: 40px;

}

.institute-basic-wrapper:hover {
  box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>